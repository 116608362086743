import { useLocation } from 'react-router-dom';
import { MobileView } from 'react-device-detect';
import { HashLink as Link } from 'react-router-hash-link';

import Logo from './../../assets/svg/logo.svg';
import AppIcon from './../../assets/svg/mobile.svg';
import LogoMobile from '../../assets/svg/logo-mobile.svg';
import LogoBranco from './../../assets/svg/logo-branco.svg';

import './Header.css';

const pagesDarkHeader = [
	'/area-de-cobertura',
	'/politica-de-privacidade',
	'/termos-de-uso',
	'/vacinas',
	'/vacinas/',
	'/testes',
	'/exames',
	'/exames/'
];

function HandleLogoPage() {
	const location = useLocation();
	const changeLogoStatus = pagesDarkHeader.some(s => {
		const routeDetail = "/" + location.pathname.split('/')[1] + "/";

		if (s === location.pathname || s === routeDetail) return location.pathname

		return null
	});

	return (
		<>
			<img src={changeLogoStatus ? LogoBranco : Logo} title="" className="img-responsive animate__animated animate__backInLeft" alt="Logotipo da pagina" />
		</>
	)
}

function HandleHeaderClass() {
	const location = useLocation();
	const areaDeCobertura = pagesDarkHeader.some(s => {
		const vacinaDetalhe = "/" + location.pathname.split('/')[1] + "/";

		if (s === location.pathname || s === vacinaDetalhe) return location.pathname

		return null
	});

	areaDeCobertura ?
		document.body.classList.add('page-area-de-cobertura') :
		document.body.classList.remove('page-area-de-cobertura');
}

export default function Header() {

	HandleHeaderClass();

	return (
		<>
			<header>
				<div className="container">
					<div className="col-12">
						<nav className="navbar navbar-expand-xl navbar-light">
							<div className="container-fluid">
								<Link className="navbar-brand" to="/">
									{HandleLogoPage()}
								</Link>
								<button className="navbar-toggler" type="button" data-bs-toggle="collapse"
									data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
									aria-label="Toggle navigation">
									<span className="navbar-toggler-icon"></span>
								</button>
								<div className="collapse navbar-collapse animate__animated animate__backInRight" id="navbarNav">
									<MobileView className="logo-mobile">
										<Link className="navbar-brand" to="/">
											<img src={LogoMobile} alt="SaudeMob" />
										</Link>
									</MobileView>

									<span id="navbar-nav-desktop">
										<ul className="navbar-nav">
											<li className="nav-item dropdown">
												<a className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false" href="/">Para você</a>
												<ul className='dropdown-menu'>
													<li><Link to="/exames">Exames</Link></li>
													<li><Link to="/vacinas">Vacinas</Link></li>
													<li><Link to="/testes">COVID</Link></li>
												</ul>
											</li>
											<li className="nav-item dropdown">
                                                <a className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false" href="/">Sobre</a>
												<ul className='dropdown-menu'>
													<li><Link to="/sobre">Quem somos</Link></li>
													<li><Link to="/como-funciona">Como funciona</Link></li>
													<li><Link to="/area-de-cobertura">Área de cobertura</Link></li>
												</ul>
											</li>
											<li className="nav-item">
												<Link className="nav-link" to="/convenios">Convênios</Link></li>
											<li className="nav-item">
												<Link className="nav-link" to="/contato">Contato</Link>
											</li>
											<li className="nav-item nav-resultados">
												<a href="##" className="nav-link">Resultado de exames</a>
												<ul>
													<li><a href="https://grupopardini.com.br/precision-care?origin=pardini" target="_blank" rel="noreferrer">Minas Gerais</a></li>
													<li><a href="https://grupopardini.com.br/precision-care?origin=cmng" target="_blank" rel="noreferrer">Rio de Janeiro</a></li>
													<li><a href="https://grupopardini.com.br/precision-care?origin=padrao" target="_blank" rel="noreferrer">Goiás</a></li>
													<li><a href="https://www.pauloazevedo.com.br/exames/resultados" target="_blank" rel="noreferrer">Pará</a></li>
												</ul>
											</li>
											<li className="nav-item nav-agende">
												<a href="https://psc.hermespardini.com.br/AgendamentoOnline/login" target="_blank" className="nav-link" rel="noreferrer">
													Agende agora
												</a>
											</li>
                                            <li className="nav-item nav-app">
												<Link className="nav-link" to="/aplicativo">
                                                    <img alt='Baixe o aplicativo' src={AppIcon} />
                                                    Baixe o<br/>aplicativo
                                                </Link>
                                            </li>
										</ul>
									</span>

									<span id="navbar-nav-mobile">
										<ul className="navbar-nav">
											<li className="nav-item dropdown">
                                                <a className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false" href="/">
													<span>
														Para você
													</span>
												</a>
												<ul className='dropdown-menu'>
													<li><Link to="/exames">
														<span data-bs-toggle="collapse"
															data-bs-target="#navbarNav">
															Exames
														</span>
													</Link>
													</li>
													<li><Link to="/vacinas">
														<span data-bs-toggle="collapse"
															data-bs-target="#navbarNav">
															Vacinas
														</span>
													</Link>
													</li>
													<li><Link to="/testes">
														<span data-bs-toggle="collapse"
															data-bs-target="#navbarNav">
															Testes
														</span>
													</Link>
														{/* <ul className="sub-testes">
                                                            <li><a href="#" title="Testes diversos">Testes diversos</a></li>
                                                            <li><a href="#" title="Testes Covid-19">Testes Covid-19</a></li>
                                                        </ul> */}
													</li>
												</ul>
											</li>
											<li className="nav-item dropdown">
                                            <a className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false" href="/">
													<span>
														Sobre
													</span>
												</a>
												<ul className='dropdown-menu'>
													<li><Link to="/sobre">
														<span data-bs-toggle="collapse"
															data-bs-target="#navbarNav">
															Quem somos
														</span>
													</Link>
													</li>
													<li><Link to="/como-funciona">
														<span data-bs-toggle="collapse"
															data-bs-target="#navbarNav">
															Como funciona
														</span>
													</Link>
													</li>
													<li><Link to="/area-de-cobertura">
														<span data-bs-toggle="collapse"
															data-bs-target="#navbarNav">
															Área de cobertura
														</span>
													</Link>
													</li>
												</ul>
											</li>
											<li className="nav-item">
												<Link className="nav-link" to="/convenios" data-bs-toggle="collapse"
													data-bs-target="#navbarNav">
													<span data-bs-toggle="collapse"
														data-bs-target="#navbarNav">
														Convênios
													</span>
												</Link>
											</li>
											<li className="nav-item">
												<Link className="nav-link" to="/contato" data-bs-toggle="collapse"
													data-bs-target="#navbarNav">
													<span data-bs-toggle="collapse"
														data-bs-target="#navbarNav">
														Contato
													</span>
												</Link>
											</li>
											<li className="nav-item nav-resultados">
												<a href="##" className="nav-link">
													<span>
														Resultado de exames
													</span></a>
												<ul>
													<li><a href="https://grupopardini.com.br/precision-care?origin=pardini" target="_blank" rel="noreferrer">Minas Gerais</a></li>

													<li><a href="https://grupopardini.com.br/precision-care?origin=cmng" title="Rio de Janeiro" target="_blank" rel="noreferrer">
														<span data-bs-toggle="collapse"
															data-bs-target="#navbarNav">
															Rio de Janeiro
														</span>
													</a>
													</li>
													<li><a href="https://grupopardini.com.br/precision-care?origin=padrao" target="_blank" title="Goiás" rel="noreferrer">
														<span data-bs-toggle="collapse"
															data-bs-target="#navbarNav">
															Goiás
														</span>
													</a>
													</li>
													<li><a href="https://www.pauloazevedo.com.br/exames/resultados" title="Pará">
														<span data-bs-toggle="collapse"
															data-bs-target="#navbarNav">
															Pará
														</span>
													</a>
													</li>
												</ul>
											</li>
											<li className="nav-item nav-agende">
												<a className="nav-link" href="https://psc.hermespardini.com.br/AgendamentoOnline/login" title="Agende agora">
													<span data-bs-toggle="collapse"
														data-bs-target="#navbarNav">
														Agende agora
													</span>
												</a>
											</li>
                                            <li className="nav-item nav-app">
                                                <Link className="nav-link" to="/aplicativo" data-bs-toggle="collapse" data-bs-target="#navbarNav">
													<span data-bs-toggle="collapse" data-bs-target="#navbarNav">
                                                        <img alt='Baixe o aplicativo' src={AppIcon} />
														Baixe o aplicativo
													</span>
												</Link>
											</li>
										</ul>
									</span>

								</div>
							</div>
						</nav>
					</div>
				</div>
			</header>
		</>
	);

}

