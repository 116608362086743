import './Sobre.css';
import { HashLink as Link } from 'react-router-hash-link';

export default function Sobre(props) {
    return (
        <>
            <section id="sobre" className={props.theme}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <span>Sobre o SaúdeMob</span>
                            <h3>Experiência de laboratório onde você estiver</h3>
                            <img src={props.imagemobile} alt="Sobre" className="img-fluid img-mobile d-block d-md-none" />
                            <p>O SaúdeMob preza pela sua segurança e conforto. Por isso, levamos a qualidade de laboratório para 
                                o lugar em que você se sente mais confortável. 
                                Tudo isso para você ter uma experiência única e ser atendido com a atenção e o cuidado que merece.
                            </p>
                            <Link to="/sobre#page-sobre" className="btn-secondary">Conheça o SaúdeMob</Link>
                        </div>
                        
                        <div className="col-md-6 d-none d-md-block">
                            <img src={props.image} alt="Sobre" className="img-fluid" />
                        </div>
                   

                    </div>
                </div>
            </section>
        </>
    )
}