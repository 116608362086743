import imgCta from '../../assets/images/img-cta-app.png';
import './CtaApp.css';
import { HashLink as Link } from 'react-router-hash-link';

export default function CtaApp(props) {
    return (
        <>
            <section id="ctaApp" className={props.theme}>
                <div className="container">
                    <div className="row">
                        <div className='col-12 col-md-6 col-lg-8 col-text'>
                            <div className='row align-items-center row-text'>
                                <div className='col-12 col-lg-6'>
                                    <h2>SaúdeMob: Delivery de Saúde</h2>
                                    <p>Com o app do SaúdeMob você pode agendar exames, testes e vacinas a qualquer hora e de qualquer lugar.</p>
                                </div>
                                <div className='col-12 col-lg-6'>
                                    <Link to="/aplicativo#root" className="btn-primary">Baixe o aplicativo</Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-lg-4'>
                            <img src={imgCta} alt="Baixe o aplicativo" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}