import './Faq.css';

export default function FAQVacinas() {
    return (
        <>
            <section id="perguntas-frequentes">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-12">
                        <h3>Perguntas frequentes</h3>
                        <p>Está com dúvidas? Consulte nossa FAQ</p>
                        <div className="accordion" id="accordionExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOnev">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOnev" aria-expanded="false" aria-controls="collapseOnev">
                                Como posso saber o valor das vacinas disponíveis?
                                </button>
                                </h2>
                                <div id="collapseOnev" className="accordion-collapse collapse" aria-labelledby="headingOnev" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                No momento, o valor das vacinas é informado no atendimento via WhatsApp.
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingTwov">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwov" aria-expanded="false" aria-controls="collapseTwov">
                                    Posso enviar minha caderneta de vacinação para que o SaúdeMob avalie quais vacinas eu preciso?
                                </button>
                                </h2>
                                <div id="collapseTwov" className="accordion-collapse collapse" aria-labelledby="headingTwov" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Não. No momento não realizamos esse tipo de análise.
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingThreev">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThreev" aria-expanded="false" aria-controls="collapseThreev">
                                    Preciso de pedido médico para tomar vacinas?
                                </button>
                                </h2>
                                <div id="collapseThreev" className="accordion-collapse collapse" aria-labelledby="headingThreev" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Não é necessário a formalização de pedido médico, mas é importante ter a avaliação de um profissional de saúde para saber se as vacinas que você precisa estão ou não em dia.
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingFourv">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFourv" aria-expanded="false" aria-controls="collapseFourv">
                                    Existe alguma parceria entre o SaúdeMob e o SUS para aplicação de vacinas via atendimento móvel?
                                </button>
                                </h2>
                                <div id="collapseFourv" className="accordion-collapse collapse" aria-labelledby="headingFourv" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Não. O SaúdeMob atua apenas em parceria com laboratórios particulares.
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}