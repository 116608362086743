import { useEffect } from 'react';
import './Aplicativo.css';
import imgApp from '../../assets/images/img-apps.png';
import iconeApp1 from '../../assets/svg/item-app-1.svg';
import iconeApp2 from '../../assets/svg/item-app-2.svg';
import iconeApp3 from '../../assets/svg/item-app-3.svg';
import Intro from '../../components/Intro/Intro';

export default function Aplicativo() {
    useEffect(() => {
        document.title = "Aplicativo | SaúdeMob";
    }, []);


    return (
        <>
        <div id='pageAplicativos'>
            <Intro pageName="Aplicativo"/>
            <section id='sobre-app'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-12 col-lg-10 col-xl-8'>
                            <h2>Sobre o aplicativo</h2>
                            <p>Agende exames, testes e vacinas onde você estiver, sem filas e sem exposição.</p>

                            <div className='row align-items-center'>
                                <div className='col-12 col-md-6'>
                                    <img alt='App SaúdeMob' src={imgApp} className='img-fluid img-app'/>
                                </div>
                                <div className='col-12 col-md-6'>
                                    <ul>
                                        <li>
                                            <img alt='Agendamento rápido e seguro' src={iconeApp1} className='icone'/>
                                            Agendamento rápido e seguro
                                        </li>
                                        <li>
                                            <img alt='Acesso aos resultados pelo app' src={iconeApp2} className='icone'/>
                                            Acesso aos resultados pelo app
                                        </li>
                                        <li>
                                            <img alt='Testes de covid' src={iconeApp3} className='icone'/>
                                            Testes de covid
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        </>
    )
}