import ServiceCards from '../ServiceCards/ServiceCards';
import './Intro.css';
import BannerHome from '../../assets/images/banner-intro.png';
import BannerQuemSomos from '../../assets/images/banner-quem-somos.png';
import BannerComoFunciona from '../../assets/images/banner-como-funciona.png';
import BannerAplicativo from '../../assets/images/banner-aplicativo.png';
import ArrowDown from '../../assets/svg/arrow-down.svg';
import AndroidBlue from '../../assets/svg/android-blue.svg';
import AndroidWhite from '../../assets/svg/android-white.svg';
import AppleBlue from '../../assets/svg/apple-blue.svg';
import AppleWhite from '../../assets/svg/apple-white.svg';
import { HashLink as Link } from 'react-router-hash-link';

export default function Intro(props) {
    function handleBannerLogo() {
        switch (props.pageName) {
            case 'Home':
                return BannerHome
            case 'QuemSomos':
                return BannerQuemSomos;
            case 'ComoFunciona':
                return BannerComoFunciona;
            case 'Aplicativo':
                return BannerAplicativo;
            default:
                return BannerHome;
        }
    }

    return (
        <>
            <section id="banner">
                <div className="container">
                    <div className="row">
                        <div className='col-12'>
                            <div className='banner-box'>
                                <div className='row align-items-center'>
                                    <div className="col-12 col-md-5 offset-md-1 animate__animated animate__backInLeft">
                                        {
                                            props.pageName === 'Home' &&
                                            <div className='texto'>
                                                <h1>Colete exames e aplique vacinas onde você estiver!</h1>
                                                <p>O SaúdeMob é como um delivery, só que de saúde! ;) Você chama e levamos a qualidade, segurança e cuidado de um laboratório exclusivo até você!</p>
                                                <a href="https://psc.hermespardini.com.br/AgendamentoOnline/login" target="_blank" className="btn btn-primary btn-dsk animate__animated animate__pulse animate__infinite	infinite" title="Agende seu atendimento" rel="noreferrer">Agende agora</a>
                                            </div>
                                        }
                                        {
                                            props.pageName === 'QuemSomos' &&
                                            <div>
                                                <h1> Um novo jeito de levar<br /> acesso à saúde para<br /> você e sua família </h1>
                                                <p> Muito mais do que atendimento<br /> domiciliar. O SaúdeMob é o seu<br /> laboratório, onde você estiver. </p>
                                                <Link to="/sobre#quem-somos" className="btn-seta">
                                                    <img src={ArrowDown} alt="Icone de seta na cor rosa, apontando para baixo." />
                                                </Link>
                                            </div>
                                        }
                                        {
                                            props.pageName === 'ComoFunciona' &&
                                            <div>
                                                <h1> Uma forma fácil
                                                    e segura de cuidar
                                                    da sua saúde
                                                </h1>
                                                <p> Com o SaúdeMob, você faz exames, testes <br/>
                                                    e toma suas vacinas no lugar que preferir. </p>
                                                <a href="#como-funciona-interna" className="btn btn-primary btn-dsk animate__animated animate__pulse animate__infinite	infinite" title="Agende seu atendimento">Veja como funciona</a>
                                                <small>
                                                    &bull; Aceitamos convênios médicos
                                                </small>
                                            </div>
                                        }
                                        {
                                            props.pageName === 'Aplicativo' &&
                                            <div className='texto'>
                                                <h1>App SaúdeMob, o delivery de saúde</h1>
                                                <p> Com o app do SaúdeMob você pode agendar exames, testes e vacinas a qualquer hora e de qualquer lugar.</p>
                                            </div>
                                        }
                                    </div>
                                    <div className='col-6 d-none d-md-block'>
                                        <img src={handleBannerLogo()} alt="Sobre" className="img-fluid img-banner" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    {
                        props.pageName === 'Home' &&
                        <ServiceCards />
                    }
                    {
                        props.pageName === 'Aplicativo' &&
                        <div className='cards-app'>
                            <div className='row justify-content-center d-none d-md-flex'>
                                <div className='col-5 col-lg-4'>
                                    <a className='box-app' href='https://play.google.com/store/apps/details?id=br.com.hermespardini.app_saudemobdev' title='App Android' target="_blank" rel="noreferrer">
                                        <img src={AndroidBlue} alt="App Android" className="icone" />
                                        <h4>Android</h4>
                                        <span className='btn btn-primary'>Baixe o aplicativo</span>
                                    </a>
                                </div>
                                <div className='col-5 col-lg-4'>
                                    <a className='box-app' href='https://apps.apple.com/br/app/sa%C3%BAdemob-seu-laborat%C3%B3rio-m%C3%B3vel/id1622446875' title='App iOS' target="_blank" rel="noreferrer">
                                        <img src={AppleBlue} alt="App iOS" className="icone" />
                                        <h4>IOS</h4>
                                        <span className='btn btn-primary'>Baixe o aplicativo</span>
                                    </a>
                                </div>
                            </div>
                            <div className='row d-flex d-md-none'>
                                <div className='col-12'>
                                    <h3>Baixe o aplicativo</h3>
                                    <a className='btn btn-primary' href='https://play.google.com/store/apps/details?id=br.com.hermespardini.app_saudemobdev' title='App Android' target="_blank" rel="noreferrer">
                                        <img src={AndroidWhite} alt="App Android" className="icone" /> Android
                                    </a>

                                    <a className='btn btn-primary' href='https://apps.apple.com/br/app/sa%C3%BAdemob-seu-laborat%C3%B3rio-m%C3%B3vel/id1622446875' title='App iOS' target="_blank" rel="noreferrer">
                                        <img src={AppleWhite} alt="App iOS" className="icone" /> iOS
                                    </a>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </section>
        </>
    )
}