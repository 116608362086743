import './Faq.css';

export default function FAQCobertura() {
    return (
        <>
            <section id="perguntas-frequentes">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-12">
                        <h3>Perguntas frequentes</h3>
                        <p>Está com dúvidas? Consulte nossa FAQ</p>
                        <div className="accordion" id="accordionExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOneco">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneco" aria-expanded="false" aria-controls="collapseOneco">
                                    Quais são as regiões atendidas pelo SaúdeMob?
                                </button>
                                </h2>
                                <div id="collapseOneco" className="accordion-collapse collapse" aria-labelledby="headingOneco" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                A nossa área de cobertura está em constante expansão. Atualmente atendemos os Estados de Minas Gerais, Rio de Janeiro e Goiás. Conheça os municípios atendidos:

                                <h5>Minas Gerais</h5>
                                Belo Horizonte, Betim, Brumadinho, Caeté, Capim Branco, Confins, Contagem, Esmeraldas, Fortuna de Minas, Ibirité, Inhaúma, Lagoa Santa, Lapinha, Matozinhos, Nova Lima, Nova União, Paraopeba, Pedro Leopoldo, Prudente de Morais, Raposos, Ribeirão das Neves, Rio Acima, Sabará, Santa Luzia, Santa Maria, São Joaquim de Bicas, São José da Lapa, Sarzedo, Sete Lagoas e Vespasiano.

                                <h5>Rio de Janeiro</h5>
                                Belford Roxo, Duque de Caxias, Itaboraí, Mesquita, Nilópolis, Niterói, Nova Iguaçu, Queimados, Rio de Janeiro, São Gonçalo e São João de Meriti.

                                <h5>Goiás</h5>
                                Abadia de Goiás, Anápolis, Aparecida de Goiânia, Aragoiânia, Brasília, Caldas Novas, Caldazinha, Goiânia, Goianira, Guapó, Hidrolândia, Senador Canedo e Trindade.

                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingTwoco">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwoco" aria-expanded="false" aria-controls="collapseTwoco">
                                Posso ser atendido em um Estado diferente de onde eu moro?
                                </button>
                                </h2>
                                <div id="collapseTwoco" className="accordion-collapse collapse" aria-labelledby="headingTwoco" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                Sim, você pode ser atendido em qualquer lugar que você preferir e que esteja dentro da nossa área de cobertura.
                                </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}