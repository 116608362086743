import './Faq.css';

export default function FAQExames() {
    return (
        <>
            <section id="perguntas-frequentes">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-12">
                        <h3>Perguntas frequentes</h3>
                        <p>Está com dúvidas? Consulte nossa FAQ</p>
                        <div className="accordion" id="accordionExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOnee">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOnee" aria-expanded="false" aria-controls="collapseOnee">
                                Onde obtenho as informações de preparo para a realização de exames?
                                </button>
                                </h2>
                                <div id="collapseOnee" className="accordion-collapse collapse" aria-labelledby="headingOnee" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                Essas informações são passadas durante o agendamento do procedimento, pelo nosso time de atendimento via WhatsApp.
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingTwoe">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwoe" aria-expanded="false" aria-controls="collapseTwoe">
                                    Como posso saber o valor dos exames atendidos?
                                </button>
                                </h2>
                                <div id="collapseTwoe" className="accordion-collapse collapse" aria-labelledby="headingTwoe" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    No momento, o valor dos exames é informado no atendimento via WhatsApp.
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingThreee">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThreee" aria-expanded="false" aria-controls="collapseThreee">
                                    Posso enviar meu pedido médico para receber um orçamento?
                                </button>
                                </h2>
                                <div id="collapseThreee" className="accordion-collapse collapse" aria-labelledby="headingThreee" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                Sim, pelo atendimento via WhatsApp você pode enviar uma cópia do seu pedido médico para que o nosso time faça o orçamento dos procedimentos para você.
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingFoure">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFoure" aria-expanded="false" aria-controls="collapseFoure">
                                    Onde posso consultar os resultados dos meus exames?
                                </button>
                                </h2>
                                <div id="collapseFoure" className="accordion-collapse collapse" aria-labelledby="headingFoure" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                Pelo site do SaúdeMob, clique em Resultados de Exames, selecione o seu Estado e nós te direcionamos para o portal do laboratório parceiro. Realize o acesso com os dados do seu protocolo para ver os laudos.

                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingFivee">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFivee" aria-expanded="false" aria-controls="collapseFivee">
                                Preciso de pedido médico para realizar exames?
                                </button>
                                </h2>
                                <div id="collapseFivee" className="accordion-collapse collapse" aria-labelledby="headingFivee" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                O pedido médico só é exigido se o exame for realizado por meio do seu convênio.
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingSixe">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSixe" aria-expanded="false" aria-controls="collapseSixe">
                                    Em quanto tempo o resultado dos meus exames ficará disponível?
                                </button>
                                </h2>
                                <div id="collapseSixe" className="accordion-collapse collapse" aria-labelledby="headingSixe" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                O prazo varia de acordo com o tipo de exame. Durante o agendamento, o nosso time de atendimento informa o prazo do procedimento que você vai realizar.
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}