import {
  Route,
  Routes,
} from "react-router-dom";


import React from "react";
import Header from '../src/components/Header/Header.jsx';
import Footer from '../src/components/Footer/Footer.jsx';


import Home from './pages/Home/Home.jsx'
import QuemSomos from './pages/QuemSomos/QuemSomos.jsx';

import './App.css';
import ComoFunciona from "./pages/ComoFunciona/ComoFunciona.jsx";
import AreaDeCobertura from "./pages/AreaDeCobertura/AreaDeCobertura.jsx";
import Exames from "./pages/Exames/Exames.jsx";
import Vacinas from "./pages/Vacinas/Vacinas.jsx";
import VacinaDetalhe from "./pages/Vacina-detalhe/Vacina-detalhe.jsx";
import Testes from "./pages/Testes/Testes.jsx";
import Convenios from "./pages/Convenios/Convenios.jsx";
import Contato from "./pages/Contato/Contato.jsx";
import Aplicativo from "./pages/Aplicativo/Aplicativo.jsx";
import PoliticaDePrivacidade from "./pages/PoliticaDePrivacidade/PoliticaDePrivacidade.jsx";
import TermosDeUso from "./pages/TermosDeUso/TermosDeUso.jsx";
import ExameDetalhe from "./pages/Exames-detalhe/Exames-detalhe.jsx";

function App() {

  return (
    <>
      <Header/>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/sobre" element={<QuemSomos />} />
        <Route path="/como-funciona" element={<ComoFunciona />} />
        <Route path="/area-de-cobertura" element={<AreaDeCobertura />} />
        <Route path="/exames" element={<Exames />} />
        <Route path="/vacinas" element={<Vacinas />} />
        <Route path="/testes" element={<Testes />} />
        <Route path="/convenios" element={<Convenios />} />
        <Route path="/contato" element={<Contato />} />
        <Route path="/aplicativo" element={<Aplicativo />} />
        <Route path='/vacinas/:id' element={<VacinaDetalhe />} />
        <Route path='/exames/:id' element={<ExameDetalhe />} />
        <Route path='/politica-de-privacidade' element={<PoliticaDePrivacidade />} />
        <Route path='/termos-de-uso' element={<TermosDeUso />} />
      </Routes>
      <Footer/>
    </>
  );
}

export default App;
