import ServiceCards from "../ServiceCards/ServiceCards";
import './Services.css';

export default function Services() {
    return (
        <>
            <div className="servicos">
                <div className="container">
                    <span>Nossos serviços</span>
                    <h5>No SaúdeMob tem:</h5>
                    <ServiceCards />
                </div>
            </div>
        </>
    )
}