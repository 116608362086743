import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import FAQ from '../../components/FAQ/Faq';
import FAQTestes from '../../components/FAQ/FaqTestes';
import FAQExames from '../../components/FAQ/FaqExames';
import FAQVacinas from '../../components/FAQ/FaqVacinas';
import FAQConvenios from '../../components/FAQ/FaqConvenios';
import FAQCobertura from '../../components/FAQ/FaqCobertura';

import './Contato.css';

export default function Contato() {
    const location = useLocation();

    useEffect(() => {
        document.title = "Contato | Saúde Mob";
    }, []);

    return (
        <>
            <section id="intro-contato">
                <div className="container">
                    <h1>Precisa de alguma ajuda?</h1>
                    <p>Entre em contato com a nossa equipe de atendimento.</p>
                    <a href={`${location.pathname}#footer`} className="btn btn-primary">Chame no Whatsapp</a>
                </div>
            </section>

            <section id="form-contato">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="info">
                                <p><strong>Horário de atendimento</strong></p>
                                <p>Seg a sex, das 09h às 18h</p>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.0117 2C6.50572 2 2.02348 6.47837 2.02148 11.9844C2.02048 13.7444 2.48147 15.4626 3.35547 16.9766L2 22L7.23242 20.7637C8.69142 21.5597 10.3339 21.9775 12.0059 21.9785H12.0098C17.5148 21.9785 21.995 17.4991 21.998 11.9941C22 9.32514 20.9622 6.81573 19.0762 4.92773C17.1902 3.04073 14.6837 2.001 12.0117 2ZM12.0098 4C14.1458 4.001 16.1531 4.8338 17.6621 6.3418C19.1711 7.8518 20 9.85819 19.998 11.9922C19.996 16.3962 16.4138 19.9785 12.0078 19.9785C10.6748 19.9775 9.35441 19.6428 8.19141 19.0078L7.51758 18.6406L6.77344 18.8164L4.80469 19.2812L5.28516 17.4961L5.50195 16.6953L5.08789 15.9766C4.38989 14.7686 4.02048 13.3874 4.02148 11.9844C4.02348 7.58238 7.60677 4 12.0098 4ZM8.47656 7.375C8.30956 7.375 8.03955 7.4375 7.81055 7.6875C7.58155 7.9365 6.93555 8.53958 6.93555 9.76758C6.93555 10.9956 7.83008 12.1826 7.95508 12.3496C8.07908 12.5156 9.68175 15.1152 12.2188 16.1152C14.3267 16.9462 14.7549 16.7822 15.2129 16.7402C15.6709 16.6992 16.6904 16.1377 16.8984 15.5547C17.1064 14.9717 17.1069 14.4702 17.0449 14.3672C16.9829 14.2632 16.8164 14.2012 16.5664 14.0762C16.3174 13.9512 15.0903 13.3486 14.8613 13.2656C14.6323 13.1826 14.4648 13.1406 14.2988 13.3906C14.1328 13.6406 13.6558 14.2012 13.5098 14.3672C13.3638 14.5342 13.2188 14.5566 12.9688 14.4316C12.7188 14.3056 11.9149 14.0414 10.9609 13.1914C10.2189 12.5304 9.71827 11.7148 9.57227 11.4648C9.42727 11.2158 9.55859 11.0791 9.68359 10.9551C9.79559 10.8431 9.93164 10.6636 10.0566 10.5176C10.1806 10.3716 10.2236 10.2676 10.3066 10.1016C10.3896 9.93556 10.3472 9.78906 10.2852 9.66406C10.2232 9.53906 9.73763 8.3065 9.51562 7.8125C9.32863 7.3975 9.13112 7.38786 8.95312 7.38086C8.80813 7.37486 8.64256 7.375 8.47656 7.375Z" fill="#FF2763" />
                                </svg>
                                <p>+55 99 11 888887777</p>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19 4H5C4.20435 4 3.44129 4.31607 2.87868 4.87868C2.31607 5.44129 2 6.20435 2 7V17C2 17.7956 2.31607 18.5587 2.87868 19.1213C3.44129 19.6839 4.20435 20 5 20H19C19.7956 20 20.5587 19.6839 21.1213 19.1213C21.6839 18.5587 22 17.7956 22 17V7C22 6.20435 21.6839 5.44129 21.1213 4.87868C20.5587 4.31607 19.7956 4 19 4ZM18.33 6L12 10.75L5.67 6H18.33ZM19 18H5C4.73478 18 4.48043 17.8946 4.29289 17.7071C4.10536 17.5196 4 17.2652 4 17V7.25L11.4 12.8C11.5731 12.9298 11.7836 13 12 13C12.2164 13 12.4269 12.9298 12.6 12.8L20 7.25V17C20 17.2652 19.8946 17.5196 19.7071 17.7071C19.5196 17.8946 19.2652 18 19 18Z" fill="#FF2763" />
                                </svg>
                                <p>contato@saudemob.com</p>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <form action="">
                                <label htmlFor="nome">
                                    Seu nome
                                </label>
                                <input type="text" name="nome" />
                                <label htmlFor="email">
                                    Seu e-mail
                                </label>
                                <input type="text" name="email" />

                                <label htmlFor="mensagem">
                                    Mensagem
                                </label>
                                <textarea type="text" name="mensagem" />

                                <input className="btn btn-primary" type="submit" value="Enviar" />

                            </form>
                        </div>
                    </div>

                </div>

                <div className="modal-sucesso">
                    <div className="container">
                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.59 0L5 3.59L1.41 0L0 1.41L3.59 5L0 8.59L1.41 10L5 6.41L8.59 10L10 8.59L6.41 5L10 1.41L8.59 0Z" fill="#2E3A59" />
                        </svg>
                        <h4>🎉 Sua mensagem foi enviada com sucesso.</h4>
                        <p>Em breve, você vai receber o contato da nossa equipe!</p>
                        <a href="##" title="ok">OK</a>
                    </div>
                </div>
            </section>


            <section id="faq-central">
                <div className="container">
                    <div className="titulo-faq">
                        <span>Perguntas frequentes</span>
                        <h3>Está com dúvidas?</h3>
                        <p>Selecione a categoria relacionada à sua dúvida.</p>
                    </div>

                </div>
                <div className="container">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="saudemob-tab" data-bs-toggle="tab" data-bs-target="#saudemob" type="button" role="tab" aria-controls="home" aria-selected="true">O SaúdeMob</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="exames-tab" data-bs-toggle="tab" data-bs-target="#exames" type="button" role="tab" aria-controls="profile" aria-selected="false">Exames</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="vacinas-tab" data-bs-toggle="tab" data-bs-target="#vacinas" type="button" role="tab" aria-controls="contact" aria-selected="false">Vacinas</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="testes-tab" data-bs-toggle="tab" data-bs-target="#testes" type="button" role="tab" aria-controls="testes" aria-selected="false">Testes</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="convenios-tab" data-bs-toggle="tab" data-bs-target="#conveniostab" type="button" role="tab" aria-controls="conveniostab" aria-selected="false">Convênios</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="cobertura-tab" data-bs-toggle="tab" data-bs-target="#coberturatab" type="button" role="tab" aria-controls="contact" aria-selected="false">Cobertura</button>
                        </li>
                    </ul>
                </div>
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="saudemob" role="tabpanel" aria-labelledby="saudemob-tab">
                        <FAQ />
                    </div>
                    <div className="tab-pane fade" id="exames" role="tabpanel" aria-labelledby="exames-tab">
                        <FAQExames />
                    </div>
                    <div className="tab-pane fade" id="vacinas" role="tabpanel" aria-labelledby="vacinas-tab">
                        <FAQVacinas />
                    </div>
                    <div className="tab-pane fade" id="testes" role="tabpanel" aria-labelledby="testes-tab">
                        <FAQTestes />
                    </div>
                    <div className="tab-pane fade" id="conveniostab" role="tabpanel" aria-labelledby="convenios-tab">
                        <FAQConvenios />
                    </div>
                    <div className="tab-pane fade" id="coberturatab" role="tabpanel" aria-labelledby="cobertura-tab">
                        <FAQCobertura />
                    </div>
                </div>

            </section>

        </>
    )
}