const agendarExame = `
  Sabia que você pode agendar o seu exame de onde você estiver*? <br> <br>
  Com o SaúdeMob você garante acesso à saúde à toda sua família. Muito mais do que um atendimento domiciliar, o SaúdeMob é o seu laboratório, onde você estiver*. <br> <br>
  Tenha um atendimento exclusivo de forma rápida, segura e acessível e com a qualidade, precisão, responsabilidade e rigor técnico de um laboratório.
`;

export const vacinas = [
  {
    id: "hepatite-a",
    titulo: "Vacina de hepatite A",
    descricao: "Causada pelo vírus VHA, a hepatite A é uma infecção aguda que pode ser transmitida através de pessoas contaminadas ou pela ingestão de água ou alimentos infectados. Uma pessoa contaminada pela hepatite A pode sentir mal estar, dores no corpo, náuseas, dor abdominal, vômitos, pele e olhos amarelados e perceber a urina escura e fezes claras. Em casos mais graves o paciente pode ter insuficiência hepática e até sangramentos. A vacina da hepatite B é a única forma de prevenção.",
    perguntas: [
      {
        pergunta: "Quem deve receber a vacina de hepatite A?",
        resposta: "A vacina de hepatite A é indicada para toda população a partir de um ano de idade. Cada pessoa deve receber duas doses da vacina com o intervalo de seis meses entre as aplicações.",
      },
      {
        pergunta: "Qual é a via de administração da vacina de hepatite A?",
        resposta: "A vacina de hepatite A é administrada via intramuscular (dentro do músculo do paciente).",
      },
      {
        pergunta: "Quais são as reações da vacina de hepatite A?",
        resposta: "Há registros de mal-estar, vômitos, náuseas, perda de apetite, fraqueza, dormência e dor nos nervos após a aplicação da vacina de hepatite A. Alguns pacientes também sentiram febre, inchaço e vermelhidão no local da aplicação. Vale lembrar que esses eventos adversos são raros.",
      },
      {
        pergunta: "Quem não deve tomar a vacina de hepatite A?",
        resposta: "Pacientes alérgicos a algum componente da vacina de hepatite A ou tiveram reações adversas na primeira dose não devem receber.",
      },
      {
        pergunta: "Como agendar a vacina de hepatite A?",
        resposta: agendarExame
      }
    ],
    whatsapp: "https://api.whatsapp.com/send?phone=553140202567&text=Ol%C3%A1%2C%20gostaria%20de%20agendar%20a%20aplica%C3%A7%C3%A3o%20m%C3%B3vel%20da%20Vacina%20para%20Hepatite%20A%2C%20pode%20me%20ajudar%3F%22"
  },
  {
    id: "febre-amarela",
    titulo: "Vacina de febre amarela",
    descricao: "Crianças, adolescentes e adultos podem se proteger da contaminação contra a febre amarela tomando a vacina. Presente no calendário vacinal do Brasil, a vacina da febre amarela é recomendada para toda a população, principalmente para aqueles que moram em áreas de risco ou pretendem viajar para esses locais. A febre amarela é transmitida pela picada dos mosquitos Sabethes ou Aedes aegypti e pode causar febre, dor muscular, náuseas, vômitos, perda de apetite e fraqueza. A vacina da febre amarela impede que o paciente evolua para a fase mais grave da doença.",
    perguntas: [
      {
        pergunta: "Quem deve tomar a vacina da febre amarela? ",
        resposta: "Bebês a partir dos nove meses e adultos até 59 anos podem receber a vacina da febre amarela. Pessoas acima de 60 anos devem receber a vacina da dengue somente se morarem em regiões com alto risco de transmissão da febre amarela ou se forem viajar para esses locais."
      },
      {
        pergunta: "Como é a aplicação da vacina da febre amarela?",
        resposta: "O paciente deve receber a vacina da febre amarela via subcutânea, ou seja, a vacina será aplicada por baixo da pele."
      },
      {
        pergunta: "Quais são as reações da vacina da febre amarela?",
        resposta: "Pacientes já registraram febre e dor no corpo após quatro dias da aplicação da vacina de febre amarela. Reações mais graves também foram relatadas, mas são raras."
      },
      {
        pergunta: "Existe alguma contraindicação para a vacina da febre amarela?",
        resposta: `Confira abaixo quais são as contraindicações para receber a vacina da febre amarela: <br>
        <ul>
          <li>Crianças menores de nove meses;</li>
          <li>Pessoas que estão amamentando menores de 6 meses;</li>
          <li>Pessoas que convivem com o HIV;</li>
          <li>Pessoas que estão se tratando com a ajuda de radioterapia ou quimioterapia;</li>
          <li>Pessoas com doenças autoimunes;</li>
          <li>Pessoas que receberam tratamento com imunossupressores.</li>
        </ul>`
      },
      {
        pergunta: "Como agendar a vacina de febre amarela?",
        resposta: agendarExame
      },
    ],
    whatsapp: "https://api.whatsapp.com/send?phone=553140202567&text=Ol%C3%A1%2C%20gostaria%20de%20agendar%20a%20aplica%C3%A7%C3%A3o%20m%C3%B3vel%20da%20Vacina%20para%20Febre%20Amarela%2C%20pode%20me%20ajudar%3F%22"
  },
  {
    id: "dtpa",
    titulo: "Vacina dTpa",
    descricao: "Também conhecida como tríplice bacteriana, a vacina dTpa é responsável por proteger a mãe e o bebê contra a transmissão direta da difteria, tétano e coqueluche, já que a vacina é aplicada a partir da 20ª semana de gestação.",
    perguntas: [
      {
        pergunta: "Quem deve tomar a vacina dTpa?",
        resposta: "A vacina dTpa deve ser administrada em grávidas a partir da 20ª semana de gestação, e em crianças, a partir dos quatro anos de idade. A vacina dTpa deve ser aplicada via intramuscular (tecido muscular)."
      },
      {
        pergunta: "Quais são as reações da vacina dTpa?",
        resposta: "A vacina dTpa pode provocar dor, inchaço, calor ou vermelhidão no local da aplicação. Alguns pacientes podem ter febre baixa. Existem registros de pessoas observarem pequenos nódulos após a aplicação."
      },
      {
        pergunta: "Como agendar a vacina dTpa?",
        resposta: agendarExame
      },
    ],
    whatsapp: "https://api.whatsapp.com/send?phone=553140202567&text=Ol%C3%A1%2C%20gostaria%20de%20agendar%20a%20aplica%C3%A7%C3%A3o%20m%C3%B3vel%20da%20Vacina%20DTPA%2C%20pode%20me%20ajudar%3F%22"
  },
  {
    id: "dengue",
    titulo: "Vacina da dengue",
    descricao: "Transmitida pelo mosquito Aedes Aegypti, a dengue pode provocar sintomas graves em alguns pacientes. Por este motivo, pessoas entre 9 e 45 anos que moram em áreas de risco ou que já foram contaminadas pelo vírus devem tomar a vacina da dengue. A vacina da dengue é feita a partir de uma versão modificada do vírus vivo, protege contra os quatro sorotipos da doença e tem eficácia geral de 82,2% na prevenção de casos confirmados.",
    perguntas: [
      {
        pergunta: "Como é o esquema da vacina da dengue?",
        resposta: "Pacientes que já tiveram contato com o vírus devem receber três doses da vacina da dengue. A aplicação deve cumprir o intervalo de seis meses entre elas."
      },
      {
        pergunta: "Como a vacina da dengue é administrada?",
        resposta: "A vacina da dengue é administrada via subcutânea, ou seja, sob a pele do braço, em crianças maiores de 9 anos, adolescentes de todas idades e adultos até 45 anos que já tiveram contato com o vírus ou moram em área de risco."
      },
      {
        pergunta: "Quais são as reações da vacina da dengue?",
        resposta: "Pacientes que recebem a vacina da dengue podem sentir mal estar, dor de cabeça, dor muscular e no local da aplicação. Há histórico de pessoas que apresentam quadro febril após 14 dias da primeira aplicação da vacina da dengue. É possível que as reações diminuam a partir da aplicação da segunda e terceira dose."
      },
      {
        pergunta: "Quais são as contraindicações da vacina da dengue?",
        resposta: `
        A vacina da dengue é contraindicada em caso de: <br>
        <ul>
          <li>Atraso menstrual ou gestação;</li>
          <li>Mulheres que estão amamentando;</li>
          <li>Febre;</li>
          <li>Uso de corticoides prolongados administrados na forma injetável ou oral;</li>
          <li>Tratamento feito à base de imunossupressores;</li>
          <li>Aplicação de vacinas vacinas de vírus vivos injetáveis com intervalo inferior de 30 dias;</li>
          <li>Pacientes com reações alérgicas graves a qualquer componente da vacina da dengue ou que tenham apresentado reação alérgica grave após a administração pré da Dengvaxia® ou vacinas que tenham os mesmos componentes.</li>
        </ul>`
      },
      {
        pergunta: "Como agendar a vacina da dengue?",
        resposta:agendarExame
      },
    ],
    whatsapp: "https://api.whatsapp.com/send?phone=553140202567&text=Ol%C3%A1%2C%20gostaria%20de%20agendar%20a%20aplica%C3%A7%C3%A3o%20m%C3%B3vel%20da%20Vacina%20da%20Dengue%2C%20pode%20me%20ajudar%3F%22"
  },
  {
    id: "bcg",
    titulo: "Vacina BCG",
    descricao: "A vacinação é o método mais eficaz para o desenvolvimento saudável de recém nascidos e crianças. A vacina BCG, por exemplo, está presente no calendário vacinal infantil e sua aplicação é de extrema importância, já que ela é responsável pela prevenção de formas graves da tuberculose.",
    perguntas: [
      {
        pergunta: "Para quem a vacina BCG é indicada?",
        resposta: "A vacina BCG é a única forma de prevenção indicada para a não evolução de formas graves da tuberculose. A aplicação deve ser feita logo após o nascimento e em crianças com até cinco anos de idade."
      },
      {
        pergunta: "Como é o esquema de aplicação da vacina BCG?",
        resposta: "A aplicação da vacina BCG deve ser feita logo após o nascimento, no braço direito do bebê. <br> <br> A vacina BCG é conhecida por ser intradérmica, ou seja, a aplicação é feita entre as camadas derme e epiderme da pele da criança. Vale lembrar que não é necessário a segunda dose da vacina BCG, exceto nos casos de contato com pacientes com hanseníase. Se esse for o caso, é necessário que o paciente receba a segunda dose da vacina BCG, com intervalo mínimo de 6 meses, com orientação médica."
      },
      {
        pergunta: "Quais são as reações adversas da vacina BCG?",
        resposta: "Normalmente, a aplicação da vacina BCG pode provocar uma pequena elevação avermelhada no braço da criança. Existem poucos registros de dificuldade na cicatrização no local da aplicação ou perceber o aparecimento de queloide ou vermelhidão."
      },
      {
        pergunta: "Como faço o agendamento da vacina BCG?",
        resposta: agendarExame
      },
    ],
    whatsapp: "https://api.whatsapp.com/send?phone=553140202567&text=Ol%C3%A1%2C%20gostaria%20de%20agendar%20a%20aplica%C3%A7%C3%A3o%20m%C3%B3vel%20da%20Vacina%20de%20BCG%2C%20pode%20me%20ajudar%3F%22"
  },
  {
    id: "hepatite-b",
    titulo: "Vacina da hepatite B",
    descricao: "A vacina da hepatite B é responsável por prevenir casos mais crônicos da hepatite, grave infecção que pode provocar sérias alterações no fígado. A hepatite pode provocar sintomas como enjoos, vômitos, febre e olhos e pele amarelados. Em alguns casos a hepatite pode causar insuficiência hepática, surgimento de feridas e desenvolvimento de câncer hepático.",
    perguntas: [
      {
        pergunta: "Como é o esquema de aplicação da vacina de hepatite B?",
        resposta: "A vacina de hepatite B deve ser aplicada em três doses, com intervalos de 30 dias entre a primeira e a segunda e 180 dias entre a primeira e a terceira dose. recém nascidos devem receber a primeira dose nas primeiras 12 horas após o nascimento. A segunda dose não deve ser aplicada antes dos seis meses de vida."
      },
      {
        pergunta: "Qual é a via de administração da vacina de hepatite B?",
        resposta: "Os pacientes devem receber a vacina da hepatite B via intramuscular (dentro do músculo)."
      },
      {
        pergunta: "Quais são as reações da vacina de hepatite B?",
        resposta: "Algumas pessoas podem sentir febre, cansaço, dor e vermelhidão no local da aplicação da vacina de hepatite B. Existem registros de náuseas e dor de cabeça."
      },
      {
        pergunta: "Como agendar a vacina de hepatite B?",
        resposta: agendarExame
      },
    ],
    whatsapp: "https://api.whatsapp.com/send?phone=553140202567&text=Ol%C3%A1%2C%20gostaria%20de%20agendar%20a%20aplica%C3%A7%C3%A3o%20m%C3%B3vel%20da%20Vacina%20para%20Hepatite%20B%2C%20pode%20me%20ajudar%3F%22"
  },
  {
    id: "hexavalente",
    titulo: "Vacina Hexavalente",
    descricao: "Para proteger os bebês contra difteria, tétano, coqueluche, hepatite B, influenza b e poliomielite causada por vírus tipos 1, 2 e 3 a recomendação é a aplicação da vacina Hexavalente.",
    perguntas: [
      {
        pergunta: "Quem deve tomar a vacina Hexavalente?",
        resposta: "Bebês a partir de 2 meses de vida devem receber uma dose da vacina Hexavalente, com reforço a partir de 1 ano de idade."
      },
      {
        pergunta: "Qual é a via de administração da vacina Hexavalente?",
        resposta: "A vacina hexavalente deve ser administrada via intramuscular (dentro do músculo)."
      },
      {
        pergunta: "Quais são as reações da vacina Hexavalente?",
        resposta: "Pacientes podem sentir febre baixa, dor, calor e inchaço no local da aplicação. Podem aparecer nódulos na pele do bebê e raramente podem ocorrer casos de irritabilidade, sonolência, febre alta ou convulsões."
      },
      {
        pergunta: "Como agendar a vacina Hexavalente?",
        resposta: agendarExame
      },
    ],
    whatsapp: "https://api.whatsapp.com/send?phone=553140202567&text=Ol%C3%A1%2C%20gostaria%20de%20agendar%20a%20aplica%C3%A7%C3%A3o%20m%C3%B3vel%20da%20Vacina%20Hexavalente%2C%20pode%20me%20ajudar%3F%22"
  },
  {
    id: "meningococica-conjugada",
    titulo: "Vacina Meningocócica Conjugada",
    descricao: "A vacina Meningocócica Conjugada quadrivalente é o método mais eficaz para proteger da meningites e infecções generalizadas causadas pela bactéria meningococo dos tipos A, C, W e Y, variações da meningite. A bactéria é transmitida através da fala, tosse ou espirros de uma pessoa contaminada, provocando febre, dor de cabeça, mal-estar, náusea, vômito, confusão mental, entre outros sintomas.",
    perguntas: [
      {
        pergunta: "Como é o esquema de aplicação da Meningocócica Conjugadas?",
        resposta: "Os pacientes devem tomar a primeira dose da vacina Meningocócica Conjugadas dos 3 aos 5 meses de vida, recebendo um reforço aos 12 meses. A vacina deve respeitar o intervalo de dois meses para a última dose."
      },
      {
        pergunta: "Qual é a via de administração da vacina Meningocócica Conjugadas?",
        resposta: "A aplicação da vacina Meningocócica Conjugadas é feita via intramuscular, ou seja, dentro do músculo."
      },
      {
        pergunta: "Quais são as reações da vacina Meningocócica Conjugadas?",
        resposta: "Há registros de febre, mal-estar e vermelhidão no local após a aplicação da vacina Meningocócica Conjugadas."
      },
      {
        pergunta: "Quais são as contraindicações da vacina Meningocócica Conjugadas?",
        resposta: "Pacientes que apresentam hipersensibilidade a vacina, febre aguda ou gestantes não devem receber a vacina Meningocócica Conjugadas."
      },
      {
        pergunta: "Como marcar a vacina Meningocócica Conjugadas?",
        resposta: agendarExame
      },
    ],
    whatsapp: "https://api.whatsapp.com/send?phone=553140202567&text=Ol%C3%A1%2C%20gostaria%20de%20agendar%20a%20aplica%C3%A7%C3%A3o%20m%C3%B3vel%20da%20Vacina%20para%20Meningoc%C3%B3cica%20Conjugada%20ACWY%2C%20pode%20me%20ajudar%3F%22"
  },
  {
    id: "meningococica-b",
    titulo: "Vacina Meningocócica B",
    descricao: "A vacina Meningocócica B é a maneira mais eficaz de proteger contra a meningite e infecções generalizadas causadas pela bactéria Meningococo do sorogrupo B. A meningite é uma inflamação das membranas que envolvem o cérebro e pode ser transmitida através da fala, tosse ou espirro de pessoas contaminadas pelo vírus ou bactéria. A contaminação pode acontecer com adultos e crianças.",
    perguntas: [
      {
        pergunta: "Como é o esquema de aplicação da Meningocócica B?",
        resposta: `Crianças acima de dois meses, adolescentes e adultos até 50 anos devem receber a vacina Meningocócica B. A vacina é indicada também para pessoas que desejam viajar para áreas de alto risco pandêmico. <br> <br>
        <strong>Confira abaixo o esquema de aplicação da vacina Meningocócica B:</strong> <br> <br>
        <ul>
          <li>A primeira dose da vacina deve ser aplicada entre 2 e 5 meses, com intervalo de dois meses;</li>
          <li>A dose de reforço deve ser entre 12 e 15 meses de vida;</li>
          <li>Se a vacina for iniciada aos 6 meses de idade, o bebê deve receber 2 doses da vacina até os 11 meses, com intervalo de dois meses;</li>
          <li>A dose de reforço deve ser aplicada no segundo ano de vida.</li>
        </ul>`
      },
      {
        pergunta: "Qual é a via de administração da vacina Meningocócica B?",
        resposta: "Os pacientes devem receber a vacina Meningocócica B via intramuscular (dentro do músculo)."
      },
      {
        pergunta: "Quais são as reações da vacina Meningocócica B?",
        resposta: "Há registros de febre, calor e inchaço no local da aplicação da vacina Meningocócica B."
      },
      {
        pergunta: "Quais são as contraindicações da vacina Meningocócica B?",
        resposta: "Pacientes com reações alérgicas a doses anteriores da vacina Meningocócica B, que apresentam febre aguda e gestantes não devem receber a dose. Gestantes só devem receber a dose com orientação médica."
      },
      {
        pergunta: "Como agendar a vacina Meningocócica B?",
        resposta: agendarExame
      },
    ],
    whatsapp: "https://api.whatsapp.com/send?phone=553140202567&text=Ol%C3%A1%2C%20gostaria%20de%20agendar%20a%20aplica%C3%A7%C3%A3o%20m%C3%B3vel%20da%20Vacina%20para%20Meningoc%C3%B3cica%20B%2C%20pode%20me%20ajudar%3F%22"
  },
  {
    id: "hpv-quadrivalente",
    titulo: "Vacina HPV quadrivalente",
    descricao: "O Papilomavírus Humano, popularmente conhecido como HPV, é uma infecção sexual que consegue entrar na pele e nas mucosas, causando alterações ou proliferações no paciente. O método mais eficaz de se proteger é tomando a vacina HPV quadrivalente. O recomendado é que homens de 9 a 45 anos e mulheres, de 9 a 26 anos, recebam a vacina. Nas mulheres, a vacina protege contra o câncer do colo do útero entre outras alterações na mucosa.",
    perguntas: [
      {
        pergunta: "Como é o esquema de aplicação da vacina HPV quadrivalente?",
        resposta: "Os pacientes devem receber a vacina HPV Quadrivalente em três doses, respeitando o intervalo mínimo de 1 mês entre a primeira e a segunda, três meses entre a terceira e a segunda, e três a quatro meses entre a primeira e a terceira dose. Menores de 15 anos devem tomar duas doses com intervalos de seis meses entre elas. Dependendo da idade do paciente, são recomendadas entre duas e três doses, dependendo do início da vacinação."
      },
      {
        pergunta: "Quais são as reações da vacina HPV Quadrivalente?",
        resposta: "Há registro de dor, vermelhidão e inchaço no local da aplicação da vacina HPV Quadrivalente. Febre, desmaio e vômitos também podem ocorrer. Raramente ocorrem reações alérgicas imediatas mais graves."
      },
      {
        pergunta: "Existem contraindicações para a vacina HPV Quadrivalente?",
        resposta: "Pacientes menores de 9 anos de idade e pessoas que já tiveram reações graves com os componentes da vacina HPV Quadrivalente não devem receber a dose. Gestantes e pessoas com quadros de febre aguda também não devem receber."
      },
      {
        pergunta: "Como agendar a vacina  HPV quadrivalente?",
        resposta: agendarExame
      },
    ],
    whatsapp: "https://api.whatsapp.com/send?phone=553140202567&text=Ol%C3%A1%2C%20gostaria%20de%20agendar%20a%20aplica%C3%A7%C3%A3o%20m%C3%B3vel%20da%20Vacina%20para%20HPV%20Quadrivalente%2C%20pode%20me%20ajudar%3F%22"
  },
  {
    id: "febre-tifoide",
    titulo: "Vacina de febre tifóide",
    descricao: "A febre tifóide é causada pela bactéria Salmonella Typh e, em caso de contaminação, pode provocar sérios problemas ao paciente como, por exemplo, febre, alterações intestinais, aumento das vísceras e, em casos mais graves, pode causar confusão mental, complicações no fígado, baço, vesícula e medula óssea. Para se proteger, é importante que crianças e adultos tomem a vacina da febre tifóide.",
    perguntas: [
      {
        pergunta: "Quem pode receber a vacina da febre tifóide?",
        resposta: "A vacina da febre tifóide é indicada para crianças, a partir dos dois anos de idade. A vacina deve ser administrada em dose única. Adolescentes e adultos que precisem viajar para áreas de alta incidência da febre tifóide devem procurar auxílio médico."
      },
      {
        pergunta: "Como a vacina da febre tifóide é aplicada?",
        resposta: "Os pacientes devem receber a vacina de febre tifóide via intramuscular (dentro do músculo)"
      },
      {
        pergunta: "Quais são as reações adversas da vacina da febre tifóide?",
        resposta: "Há registro de dor, inchaço e vermelhidão após a aplicação da vacina da febre tifóide."
      },
      {
        pergunta: "Existe alguma contraindicação para a vacina de febre tifóide?",
        resposta: "Pessoas com alergia a algum componente da vacina e crianças menores de dois anos não devem receber a vacina da febre tifóide."
      },
      {
        pergunta: "Como agendar a vacina de febre tifóide?",
        resposta: agendarExame
      },
    ],
    whatsapp: "https://api.whatsapp.com/send?phone=553140202567&text=Ol%C3%A1%2C%20gostaria%20de%20agendar%20a%20aplica%C3%A7%C3%A3o%20m%C3%B3vel%20da%20Vacina%20para%20Febre%20Tif%C3%B3ide%2C%20pode%20me%20ajudar%3F%22"
  },
  {
    id: "herpes-zoster",
    titulo: "Vacina Herpes Zóster Atenuada",
    descricao: "Provocado pelo mesmo vírus da catapora, a Herpes Zóster é uma grave infecção que pode causar coceira, formigamento, bolhas, dor de cabeça, febre, distúrbios intestinais e dor no local afetado. A vacina Herpes Zóster Atenuada é o método mais eficaz de prevenção, que normalmente ocorre em adultos acima dos 60 anos de idade.",
    perguntas: [
      {
        pergunta: "Quem deve tomar a vacina Herpes Zóster Atenuada?",
        resposta: "Adultos acima de 50 anos devem tomar a vacina Herpes Zóster Atenuada, faixa etária com maior risco de desenvolver complicações caso seja infectado."
      },
      {
        pergunta: "Como a vacina Herpes Zóster Atenuada deve ser administrada?",
        resposta: "A administração da vacina Herpes Zóster Atenuada deve ser aplicada via subcutânea."
      },
      {
        pergunta: "Quais são as reações da vacina Herpes Zóster Atenuada?",
        resposta: "A vacina Herpes Zóster Atenuada pode causar dor, vermelhidão e inchaço no local da aplicação. Há registros de formação de nódulos subcutâneos."
      },
      {
        pergunta: "Quais são as contraindicações da vacina Herpes Zóster Atenuada?",
        resposta: "Pessoas com tuberculose ativa ainda não tratada, gestantes e pessoas com alergia grave a algum componente da vacina Herpes Zóster Atenuada não devem receber a dose. <br> <br> A vacina também é contraindicada para aqueles pacientes imunossuprimidos por doenças ou medicamentos. Se faz uso de medicamentos potencialmente imunossupressores, consulte seu médico."
      },
      {
        pergunta: "Como agendar a Vacina Herpes Zóster Atenuada?",
        resposta: agendarExame
      },
    ],
    whatsapp: "https://api.whatsapp.com/send?phone=553140202567&text=Ol%C3%A1%2C%20gostaria%20de%20agendar%20a%20aplica%C3%A7%C3%A3o%20m%C3%B3vel%20da%20Vacina%20para%20Herpes%20Zoster%2C%20pode%20me%20ajudar%3F%22"
  },
  {
    id: "dtpa-ipv",
    titulo: "Vacina dTpa-IPV",
    descricao: "Manter o cartão de vacinação das crianças atualizado garante que ela tenha todos os anticorpos necessários para ter uma infância saudável. Presente no calendário infantil, toda criança, a partir dos três anos de idade, devem receber a vacina  dTpa-IPV, que protege contra a difteria, tétano, coqueluche e poliomielite. Vale lembrar que assim como as crianças, adolescentes e adultos devem receber uma nova dose do imunizante a cada 10 anos.",
    perguntas: [
      {
        pergunta: "Como é o esquema de aplicação da vacina dTpa-IPV?",
        resposta: `
        O esquema de aplicação da vacina dTpa-IPV deve se feito da seguinte maneira: <br> <br>
        <ul>
          <li>Reforço para crianças a partir dos três anos de idade;</li>
          <li>Dose de reforço a cada 10 anos para adultos;</li>
          <li>Gestantes devem receber a vacina dTpa a partir da 20ª semana de gravidez;</li>
        </ul>

        Vale lembrar que a vacina dTpa-IPV deve ser aplicada sempre que houver necessidade de reforço da poliomielite e para pessoas que moram ou irão viajar para áreas de risco à doença.
        `
      },
      {
        pergunta: "Qual é a via de administração da vacina dTpa-IPV?",
        resposta: "O paciente deve receber a vacina dTpa-IPV dentro do músculo (via intramuscular)."
      },
      {
        pergunta: "Quais são as reações da vacina dTpa-IPV?",
        resposta: "Há registros de reações adversas como dor e vermelhidão no local da aplicação da vacina dTpa-IPV. Existem ocorrências de febre baixa, dor, calor ou inchaço no braço que recebeu a dose da vacina dTpa-IPV. Em alguns casos é possível perceber a aparição de pequenos nódulos."
      },
      {
        pergunta: "Como agendar  a vacina dTpa-IPV?",
        resposta: agendarExame
      },
    ],
    whatsapp: "https://api.whatsapp.com/send?phone=553140202567&text=Ol%C3%A1%2C%20gostaria%20de%20agendar%20a%20aplica%C3%A7%C3%A3o%20m%C3%B3vel%20da%20Vacina%20DTPA-IPV%2C%20pode%20me%20ajudar%3F%22"
  },
  {
    id: "hepatite-a-b",
    titulo: "Vacina de hepatite A e B",
    descricao: "A vacina é a principal forma de prevenção contra a hepatite causada por vírus. Completamente segura e eficaz, a vacina da Hepatite A e B protege das duas variações da doença podendo, inclusive, evitar a hepatite D. A hepatite é uma inflamação do fígado que pode ser provocada através de bactérias, vírus ou pelo consumo de substâncias contidas em medicamentos ou álcool.",
    perguntas: [
      {
        pergunta: "Quem deve receber a vacina de hepatite A e B?",
        resposta: "Crianças a partir de 1 ano de idade, adolescentes, adultos e idosos que ainda não foram vacinados anteriormente podem receber a vacina da hepatite A e B. <br> <br> Crianças acima de 1 ano até 15 anos de idade devem tomar duas doses com seis meses de intervalo. Já adolescentes, a partir de 16 anos, adultos e idosos precisam tomar três doses, sendo a segunda um mês após a primeira aplicação, e a terceira seis meses depois da primeira."
      },
      {
        pergunta: "Qual é a via de administração da vacina de hepatite A e B?",
        resposta: "Os pacientes devem receber a vacina de hepatite  A e B via intramuscular (dentro do músculo)."
      },
      {
        pergunta: "Quais são as reações da vacina de hepatite A e B?",
        resposta: "Há registros raros de mal estar, vômitos, náuseas, perda de apetite, fraqueza, dormência nas articulações após a aplicação da vacina de hepatite A e B."
      },
      {
        pergunta: "Quem não deve tomar a vacina de hepatite A e B?",
        resposta: "Pacientes com histórico de alergia de algum componente da vacina de hepatite A e B não devem receber a dose. Gestantes devem tomar a vacina apenas com orientação médica."
      },
      {
        pergunta: "Como agendar a vacina de hepatite A e B?",
        resposta: agendarExame
      },
    ],
    whatsapp: "https://api.whatsapp.com/send?phone=553140202567&text=Ol%C3%A1%2C%20gostaria%20de%20agendar%20a%20aplica%C3%A7%C3%A3o%20m%C3%B3vel%20da%20Vacina%20para%20Hepatite%20A%20e%20B%2C%20pode%20me%20ajudar%3F%22"
  },
  {
    id: "haemophilus-influenzae-b",
    titulo: "Vacina Haemophilus influenzae tipo B",
    descricao: "O contato com pessoas infectadas pela bactéria Haemophilus influenzae tipo B pode causar infecções no nariz e garganta e, em alguns casos, pode se espalhar e atingir a pele, ouvidos, pulmões e articulações, chegando até mesmo nas membranas que revestem o coração. Apenas a vacina Haemophilus Influenzae tipo B é a principal forma de prevenção, prevenindo até mesmo contra infecções graves como meningite e pneumonia.",
    perguntas: [
      {
        pergunta: "Quem deve receber a vacina influenzae tipo B?",
        resposta: "Crianças menores de cinco anos devem tomar a vacina Haemophilus Influenzae tipo B. Em alguns casos, a vacina vacina Haemophilus influenzae tipo B também deve ser aplicada em crianças maiores de cinco anos, adolescentes e adultos que apresentam condições médicas que podem aumentar as chances de contaminação."
      },
      {
        pergunta: "Qual é a vida de administração da vacina Haemophilus Influenzae tipo B?",
        resposta: "A aplicação da vacina Haemophilus Influenzae tipo B é via intramuscular (aplicada diretamente dentro do músculo)."
      },
      {
        pergunta: "Quais são as reações da vacina Haemophilus Influenzae tipo B?",
        resposta: "A vacina Haemophilus Influenzae tipo B pode causar febre baixa, sonolência, dor, vermelhidão e inchaço no local da aplicação. Algumas pessoas também podem perceber a formação de um pequeno nódulo no local."
      },
      {
        pergunta: "Quem não deve tomar a vacina Haemophilus Influenzae tipo B?",
        resposta: "Pacientes que registraram alergia aos componentes da vacina Haemophilus Influenzae tipo B ou apresentaram reações à dose anterior da vacina. Pessoas que apresentam quadro de febre aguda também não devem receber a vacina influenzae tipo B."
      },
      {
        pergunta: "Como agendar a vacina Haemophilus Influenzae tipo B?",
        resposta: agendarExame
      },
    ],
    whatsapp: "https://api.whatsapp.com/send?phone=553140202567&text=Ol%C3%A1%2C%20gostaria%20de%20agendar%20a%20aplica%C3%A7%C3%A3o%20m%C3%B3vel%20da%20Vacina%20Haemophilus%20Influenzae%20B%2C%20pode%20me%20ajudar%3F%22"
  },
  {
    id: "pentavalente",
    titulo: "Vacina Pentavalente",
    descricao: "A vacina Pentavalente é conhecida por ser a combinação de cinco vacinas individuais, responsável por proteger contra a difteria, tétano, coqueluche, hepatite B e contra influenza tipo B. A vacina faz parte do calendário vacinal das crianças e é de extrema importância para o seu crescimento.",
    perguntas: [
      {
        pergunta: "Como é o esquema de aplicação da vacina Pentavalente?",
        resposta: "A vacina Pentavalente é indicada para crianças aos 2, aos 4 e aos 6 meses de vida. A vacina deve ser administrada em 3 doses."
      },
      {
        pergunta: "Qual é a via de administração da vacina Pentavalente?",
        resposta: "Os bebês devem receber a vacina Pentavalente via intramuscular (dentro do músculo)."
      },
      {
        pergunta: "Quais são as reações da vacina Pentavalente?",
        resposta: "Há registros de febre baixa, dor, calor e inchaço no local da aplicação da vacina Pentavalente. Existem raros casos de irritabilidade, sonolência, convulsões febris ou febre alta."
      },
      {
        pergunta: "Quais são as contraindicações da vacina Pentavalente?",
        resposta: 
        `
        Veja abaixo para quem a vacina Pentavalente é contraindicada: <br>
        <ul>
          <li>Pessoas com quadro de febre aguda;</li>
          <li>Pessoas com histórico de reação grave após o recebimento da vacina dTpa, dT, tétano, pólio inativa, hemófilos ou hepatite B não devem receber a dose da vacina Pentavalente.</li>
        </ul>`
      },
      {
        pergunta: "Como marcar a vacina Meningocócica Conjugadas?",
        resposta: agendarExame
      },
    ],
    whatsapp: "https://api.whatsapp.com/send?phone=553140202567&text=Ol%C3%A1%2C%20gostaria%20de%20agendar%20a%20aplica%C3%A7%C3%A3o%20m%C3%B3vel%20da%20Vacina%20Pentavalente%2C%20pode%20me%20ajudar%3F%22"
  },
  {
    id: "pneumococica-13-valente-conjugada",
    titulo: "Vacina Pneumocócica 13-valente Conjugada",
    descricao: "Doenças graves como pneumonia, meningite e otite, causadas pelo vírus Streptococcus pneumoniae, podem ser evitadas com a vacina pneumocócica 13-valente conjugada. A vacina é extremamente segura e é indicada para crianças, adolescentes e adultos. Antes de recebê-la, consulte um médico.",
    perguntas: [
      {
        pergunta: "Quem deve receber a vacina pneumocócica 13-valente conjugada?",
        resposta: "Bebês a partir dos 2 meses de vida devem receber a vacina pneumocócica 13-valente conjugada. A vacina também é recomendada para adultos acima dos 60 anos de idade. Pessoas entre 6 e 59 anos só devem receber a dose da vacina com pedido médico."
      },
      {
        pergunta: "Qual é a via de administração da vacina pneumocócica 13-valente conjugada?",
        resposta: "A vacina pneumocócica 13 valente conjugada deve ser aplicada via intramuscular profunda."
      },
      {
        pergunta: "Quais são as reações da vacina pneumocócica 13-valente conjugada?",
        resposta: "Pacientes podem perceber vermelhidão, dor, inchaço e coceira no local da aplicação. Também há registros de dor de cabeça, muscular ou nas articulações."
      },
      {
        pergunta: "Quais são as contraindicações da  vacina pneumocócica 13-valente conjugada?",
        resposta: "Alérgicos e aos componentes não devem receber a vacina pneumocócica 13-valente conjugada."
      },
      {
        pergunta: "Como agendar a Vacina pneumocócica 13-valente conjugada em Goiânia?",
        resposta: agendarExame
      },
    ],
    whatsapp: "https://api.whatsapp.com/send?phone=553140202567&text=Ol%C3%A1%2C%20gostaria%20de%20agendar%20a%20aplica%C3%A7%C3%A3o%20m%C3%B3vel%20da%20Vacina%20Pneumoc%C3%B3cica%2013-Valente%20Conjugada%2C%20pode%20me%20ajudar%3F%22"
  },
  {
    id: "pneumococica-polivalente",
    titulo: "Vacina pneumocócica polivalente",
    descricao: "Causadas pela bactéria Streptococcus pneumoniae, as doenças pneumológicas podem ser evitadas com a vacina pneumocócica polivalente. Pneumonia, meningite e otite, uma inflamação aguda do ouvido, são alguns exemplos de doenças que podem ser evitados com a dose da vacina.",
    perguntas: [
      {
        pergunta: "Quem deve receber a vacina pneumocócica polivalente?",
        resposta: "Crianças a partir de 2 anos de idade, adolescentes e adultos que apresentam um quadro que possam aumentar o risco pneumocócica devem tomar a vacina pneumocócica polivalente. A vacina também é recomendada a partir dos 60 anos de idade."
      },
      {
        pergunta: "Qual é a via de administração da vacina pneumocócica polivalente?",
        resposta: "Os pacientes devem receber a vacina pneumocócica polivalente via intramuscular profunda."
      },
      {
        pergunta: "Quais são as reações da vacina pneumocócica polivalente?",
        resposta: "Os pacientes podem perceber calor, inchaço e dor no local da aplicação da vacina pneumocócica polivalente. Pode ocorrer a formação de um nódulo na pele. "
      },
      {
        pergunta: "Quais são as contraindicações da vacina pneumocócica polivalente?",
        resposta: "Pessoas que apresentem anafilaxia causada por algum componente da vacina pneumocócica polivalente não devem receber a dose."
      },
      {
        pergunta: "Como agendar a vacina pneumocócica polivalente?",
        resposta: agendarExame
      },
    ],
    whatsapp: "https://api.whatsapp.com/send?phone=553140202567&text=Ol%C3%A1%2C%20gostaria%20de%20agendar%20a%20aplica%C3%A7%C3%A3o%20m%C3%B3vel%20da%20Vacina%20Pneumoc%C3%B3cica%20Pentavalente%2C%20pode%20me%20ajudar%3F%22"
  },
  {
    id: "rotavirus",
    titulo: "Vacina rotavírus",
    descricao: "O rotavírus é um vírus que pode causar sérios problemas gastrointestinais ao paciente. A contaminação pode acontecer pelo contato com água, utensílios, brinquedos ou alimentos contaminados. Vale lembrar que o vírus, responsável pela diarréia aguda e gastroenterite, pode ser evitado pela vacina rotavírus.",
    perguntas: [
      {
        pergunta: "Como é o esquema de aplicação da vacina rotavírus?",
        resposta: "A vacina rotavírus deve ser administrada de forma oral nos primeiros meses de vida. A segunda dose da vacina rotavírus pode ser recebida entre o 3º e 5º mês."
      },
      {
        pergunta: "Qual é a via de administração da vacina rotavírus?",
        resposta: "A vacina rotavírus deve ser administrada via oral."
      },
      {
        pergunta: "Quais são as reações da vacina rotavírus?",
        resposta: "A vacina rotavírus pode provocar febre, vômito, diarreia e irritabilidade no paciente. Há registros de otite e faringite."
      },
      {
        pergunta: "Quais são as contraindicações da vacina rotavírus?",
        resposta: "Pessoas com alergia grave aos componentes, lactentes com suspeita ou diagnóstico de imunodeficiência ou malformações intestinais não devem receber a vacina rotavírus."
      },
      {
        pergunta: "Como marcar a vacina rotavírus?",
        resposta: agendarExame
      },
    ],
    whatsapp: "https://api.whatsapp.com/send?phone=553140202567&text=Ol%C3%A1%2C%20gostaria%20de%20agendar%20a%20aplica%C3%A7%C3%A3o%20m%C3%B3vel%20da%20Vacina%20Rotav%C3%ADrus%20Polivalente%2C%20pode%20me%20ajudar%3F%22"
  },
  {
    id: "sarampo-caxumba-rubeola-varicela",
    titulo: "Vacina contra sarampo, caxumba, rubéola e varicela",
    descricao: "Conhecida como tetra viral ou tetravalente viral, a vacina contra sarampo, caxumba, rubéola e varicela.",
    perguntas: [
      {
        pergunta: "Qual é a via de administração da vacina contra sarampo, caxumba, rubéola e varicela?",
        resposta: "A vacina contra sarampo, caxumba, rubéola e varicela deve ser aplicada via subcutânea, ou seja, a vacina é aplicada no tecido adiposo, logo abaixo da derme."
      },
      {
        pergunta: "Qual é o esquema de vacinação da vacina contra sarampo, caxumba, rubéola e varicela?",
        resposta: "A primeira dose da vacina contra sarampo, caxumba, rubéola e varicela deve ser aplicada a partir de 1 ano de idade. Para receber a segunda dose, a criança precisa ter 1 ano e 3 meses. É importante que o intervalo de 3 meses entre a primeira e segunda dose seja respeitado. Vale lembrar que se o médico solicitar, a vacina contra sarampo, caxumba, rubéola e varicela pode ser aplicada a partir dos 9 meses de idade, sendo que essa dose não será contada para o esquema de duas doses."
      },
      {
        pergunta: "Quais são os efeitos colaterais da vacina contra sarampo, caxumba, rubéola e varicela? ",
        resposta: "O paciente pode sentir dor, febre, calor nas articulações e perceber manchas vermelhas até 12 dias após a aplicação da vacina contra sarampo, caxumba, rubéola e varicela."
      },
      {
        pergunta: "Quais são as contraindicações da vacina contra sarampo, caxumba, rubéola e varicela?",
        resposta: "Pacientes alérgicos aos componentes da vacina ou com histórico de anafilaxia não devem receber a dose da vacina contra sarampo, caxumba, rubéola e varicela. Pacientes com deficiência da imunidade também não devem ser vacinados."
      },
      {
        pergunta: "Como marcar a vacina contra sarampo, caxumba rubéola e varicela?",
        resposta: agendarExame
      },
    ],
    whatsapp: "https://api.whatsapp.com/send?phone=553140202567&text=Ol%C3%A1%2C%20gostaria%20de%20agendar%20a%20aplica%C3%A7%C3%A3o%20m%C3%B3vel%20da%20Vacina%20para%20Caxumba%2CRub%C3%A9ula%20e%20Varicela%2C%20pode%20me%20ajudar%3F%22"
  },
  {
    id: "triplice-viral",
    titulo: "Vacina tríplice viral",
    descricao: "Também conhecida popularmente como “vacina triviral”, a vacina tríplice viral faz parte do calendário vacinal e deve ser aplicada em crianças a partir de 1 ano de idade. A vacina tríplice viral é responsável por proteger contra o sarampo, a caxumba e a rubéola. Vale lembrar que a proteção da vacina inicia-se duas semanas após a aplicação e sua duração é geralmente por toda a vida.",
    perguntas: [
      {
        pergunta: "Qual é a via de administração da vacina tríplice viral?",
        resposta: "A vacina tríplice viral deve ser aplicada via subcutánea."
      },
      {
        pergunta: "Qual é o esquema de vacinação da vacina tríplice viral?",
        resposta: "A primeira dose da vacina tríplice viral deve ser aplicada em crianças a partir de 1 ano de idade. E a segunda dose deve ser administrada três meses após a primeira. Adultos nunca vacidados também devem receber duas doses com intervalo de 30 dias. Aqueles que só receberam uma dose no passado devem receber uma dose."
      },
      {
        pergunta: "Quais são os efeitos colaterais da vacina tríplice viral? ",
        resposta: "Os pacientes podem sentir febre, irritabilidade, vermelhidão nos olhos, coriza, manchas vermelhas no corpo e ínguas após a aplicação da vacina tríplice viral. Há relatos sobre aparecimento de vermelhidão, dor, coceira ou inchaço no local da aplicação."
      },
      {
        pergunta: "Quais são as contraindicações da vacina tríplice viral?",
        resposta: `
        Confira abaixo quem não deve receber a vacina tríplice viral: <br> <br>
        <ul>
          <li>Gestantes;</li>
          <li>Pessoas com doenças que afetem o sistema imunológico;</li>
          <li>Pacientes com alergia à Neomicina ou algum dos componentes da vacina;</li>
          <li>Pessoas com febre aguda ou com sintomas de infecção devem consultar o médico antes de receber a dose da vacina tríplice viral.</li>
        </ul>
        `
      },
      {
        pergunta: "Como marcar a vacina contra tríplice viral?",
        resposta: agendarExame
      },
    ],
    whatsapp: ""
  },
];