import Services from "../../components/Services/Services";
import ListaConvenios from "../../components/Convenios/Convenios";
import './Convenios.css';
import ComoFuncionaConvenios from "../../components/ComoFunciona/Convenios/ComoFuncionaConvenios";
import CondicoesPagamento from "../../components/CondicoesPagamento/CondicoesPagamento";
import { useEffect } from "react";

export default function Convenios() {
    useEffect(() => {
        document.title = "Convenios | Saúde Mob";
    }, []);

    return (
        <>
            <div className="page-convenios">
                <div id="intro-convenios">
                    <div className="container">
                        <h1>Aceitamos os principais planos de saúde</h1>
                        <p>Aqui, no SaúdeMob, o <span>acesso à saúde é de verdade.</span></p>
                        <p>Use o seu convênio para realizar seus procedimentos com a gente.</p>
                    </div>
                </div>


            <ListaConvenios />

            <Services />

            <ComoFuncionaConvenios theme="como-funciona-convenios" />
            
            <div id="convenios-nao-encontrado">
                <div className="container">
                    <h3>Não encontrou o seu convênio? </h3>
                    <p>Com o SaúdeMob, você também pode fazer os seus procedimentos de modo particular.</p>
                </div>
                <CondicoesPagamento pageName="Convenios" />
            </div>
            </div>

        </>
    )
}