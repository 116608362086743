import React, {useEffect} from 'react';
import FAQ from '../../components/FAQ/Faq';
import Intro from '../../components/Intro/Intro';
import PadraoAtendimento from '../../components/PadraoAtendimento/PadraoAtendimento';
import Sobre from '../../components/Sobre/Sobre';
import './ComoFunciona.css';
import imgSobre from './../../assets/images/sobre2.png';
import imgSobreMobile from '../../assets/images/sobre-2-mobile.png';
import Interna from '../../components/ComoFunciona/Interna/Interna';

export default function ComoFunciona() {
    useEffect(() => {
        document.title = "Como Funciona | Saúde Mob";
    }, []);


    return (
        <>
        <div id="page-como-funciona">
            <Intro pageName="ComoFunciona"/>
            <Interna />
            <Sobre theme="light" image={imgSobre} imagemobile={imgSobreMobile} />
            <PadraoAtendimento />
            <FAQ />
            </div>
        </>
    )
}
