
import { useLocation, Navigate } from 'react-router-dom';
import { useEffect } from 'react';

import Sobre from '../../components/Sobre/Sobre';
import './Exames-detalhe.css';

import imgExames from '../../assets/images/exames.png';
import imgSobreMobile from '../../assets/images/sobre-mobile.png';

import { exames } from "../../config/listagem-exames";

export default function ExameDetalhe() {
  const location = useLocation();
  const exameAtual = exames.find(item => ("/exames/" + item.id) === location.pathname);

  useEffect(() => {
    if(exameAtual) {
      const title = exameAtual.titulo + " | Saúde Mob";
      document.title = title;
      window.scrollTo(0, 0);
    }
  }, [exameAtual])
  
  if (!exameAtual) {
    return <Navigate to='/exames' />
  }

  const listaDePerguntasExame = exameAtual.perguntas.map((item, i) =>
    <div className="perguntas" key={i}>
      <h4>{item.pergunta}</h4>
      <p dangerouslySetInnerHTML={{ __html: item.resposta }}></p>
    </div>
  );

  return (
    <>
      <section id="exame-detalhes" className="intro-exames">
        <div className="container">
          <h1>{exameAtual.titulo}</h1>
          <p>{exameAtual.descricao}</p>
        </div>
      </section>

      <section id="perguntas-exame">
        <div className="container">
          {listaDePerguntasExame}
        </div>

        <div className="d-flex align-items-center justify-content-center w-100">
          <a href={exameAtual.whatsapp} target="_blank" className="btn-primary" title="Agende agora" rel="noreferrer">Agende agora mesmo o seu exame pelo whatsapp</a>
        </div>
      </section>

      <div id="wrapper-saudemob">
        <div className="container">
          <h1>Com a Saúde Mob você terá:</h1>

          <div className='d-flex align-items-center justify-content-center'>
            <ul className='lista'>
              <li>Equipe especializada e pronta para te atender no local de sua preferência;</li>
              <li>Evite filas e exposição;</li>
              <li>Agendamento rápido, via whatsapp;</li>
              <li>Resultados online ou entregues no conforto da sua casa;</li>
              <li>Atendimento particular com planos de saúde.</li>
            </ul>
          </div>

          <strong className='text-center'>SaúdeMob, acesso à saúde para quem precisa, onde estiver*.</strong>
          <p className='requisitos mt-3 mb-4'>*De acordo com os requisitos dispostos na RDC 302/2005</p>
        
          <div className="d-flex align-items-center justify-content-center w-100 pb-5">
            <a href={exameAtual.whatsapp} target="_blank" className="btn-primary" title="Agende agora" rel="noreferrer">Agende agora</a>
          </div>
        </div>
      </div>

      <Sobre title="Sobre o SaúdeMob" image={imgExames} imagemobile={imgSobreMobile} theme="sobre-exames" />
    </>
  );
}