import { useEffect } from "react";
import './PoliticaDePrivacidade.css';

export default function PoliticaDePrivacidade() {
  useEffect(() => {
    document.title = "Política de Privacidade | Saúde Mob";
  }, []);

  return (
    <>
      <section id="politicaDePrivacidade">
        <div className="container">
          <h1>Política de Privacidade</h1>
          <p>Política de Privacidade e Proteção de Dados Pessoais</p>
        </div>
      </section>

      <section id="intro">
        <div className="container">
          <h3 className="title">Introdução</h3>

          <div className="description">
            <p>
              O grupo Hermes Pardini cumpridor de seus deveres e obrigações adota medidas rigorosas de privacidade e proteção de dados pessoais. Entendemos que você está ciente e se preocupa com seus interesses pessoais de privacidade.
            </p>
            <p>
              Esta Política de Privacidade e Proteção de Dados Pessoais expressa nosso compromisso com o tratamento de seus dados pessoais de modo responsável, ético, em linha com nossos princípios e valores e, especialmente, de acordo com as regras da Lei nº 13.709/2018 (Lei Geral de Proteção de Dados Pessoais - “LGPD”) e demais legislações vigentes aplicáveis. Reconhecemos que esta é uma responsabilidade constante e, portanto, atualizaremos periodicamente esta Política à medida que se fizerem necessários novos procedimentos de privacidade e proteção de seus dados pessoais.
            </p>
            <p>
              <strong>Ao utilizar nossos serviços, bem como nosso site ou aplicativo, você concorda com o tratamento de seus dados pessoais como está descrito nesta Política de Privacidade e Proteção de Dados Pessoais.</strong>
            </p>
            <p>
              Se você tiver alguma dúvida sobre esta Política de Privacidade e Proteção de Dados Pessoais, entre em contato com o(a) nosso(a) DPO/Encarregado(a), Fabiana Ricco, através do e-mail privacidade@grupopardini.com.br.
            </p>
          </div>

          <h3 className="title">Definições</h3>

          <div className="description">
            <p>
              <strong>Adolescente:</strong> pessoa física entre 12 (doze) e 18 (dezoito) anos de idade, segundo o Estatuto da Criança e do Adolescente (“ECA”).
            </p>
            <p>
              <strong>Agentes de tratamento:</strong> controlador e/ou operador.
            </p>
            <p>
              <strong>Autoridade Nacional de Proteção de Dados – ANPD:</strong> órgão da administração pública responsável por zelar, implementar e fiscalizar o cumprimento da LGPD em todo o território nacional.
            </p>
            <p>
              <strong>Anonimização:</strong> utilização de meios técnicos razoáveis e disponíveis no momento do tratamento, por meio dos quais um dado perde a possibilidade de associação, direta ou indireta, a um indivíduo.
            </p>
            <p>
              <strong>Bloqueio:</strong> suspensão temporária de qualquer operação de tratamento, mediante guarda do dado pessoal.
            </p>
            <p>
              <strong>Cliente:</strong> pessoa física que utiliza os serviços do grupo Hermes Pardini.
            </p>
            <p>
              <strong>Criança:</strong> pessoa física com até 12 (doze) anos de idade incompletos, segundo o Estatuto da Criança e do Adolescente (“ECA”).
            </p>
            <p>
              <strong>Compartilhamento:</strong> transferência ou interconexão de dados pessoais com áreas internas ou terceiros.
            </p>
            <p>
              <strong>Confidencialidade:</strong> garantia de que os dados pessoais não serão divulgados para pessoas não autorizadas.
            </p>
            <p>
              <strong>Controlador:</strong> pessoa física ou jurídica, de direito público ou privado, a quem compete a decisão sobre o tratamento do dado pessoal.
            </p>
            <p>
              <strong>Consentimento:</strong> manifestação livre, informada e inequívoca do titular do dado confirmando sua concordância quanto ao tratamento de seus dados pessoais.
            </p>
            <p>
              <strong>Cookies:</strong> pequenos arquivos de texto enviados pelo site ao computador do Usuário e que nele ficam armazenados com informações relacionadas à navegação do site. São utilizados para fazer o site funcionar ou funcionar de forma mais eficiente, bem como para fornecer informações aos proprietários do site, de forma que seja possível reconhecer e lembrar de suas preferências. Essas informações podem ser utilizadas para fornecer um serviço mais personalizado e ágil.
            </p>
            <p>
              <strong>Dado Pessoal:</strong> informação que individualmente ou em conjunto com outras, permite a identificação do indivíduo de forma direta ou indireta, tal como, nome, endereço, e-mail, CPF, RG, título de eleitor, telefone (s), profissão, sexo, data de nascimento, estado civil, grau de instrução, nacionalidade, dados do cônjuge/dependentes, entre outros.
            </p>
            <p>
              <strong>Dado Pessoal Sensível:</strong> categoria de dado pessoal que, pelo seu potencial discriminatório, requer um nível extra de proteção e um elevado dever de cuidado. São dados pessoais que podem revelar origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou à organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa natural.
            </p>
            <p>
              <strong>Dado Pessoal de Geolocalização:</strong> categoria de dados pessoais referentes à localização via GPS e WIFI obtidos a partir do dispositivo móvel do usuário, a fim de proporcionar aos usuários a melhor experiência dentro do app do grupo Hermes Pardini.
            </p>
            <p>
              <strong>Acesso aos arquivos:</strong> Utilizaremos a escrita/gravação (WRITE_EXTERNAL_STORAGE) e leitura (READ_EXTERNAL_STORAGE) de dados no seu aparelho para que você possa visualizar, baixar e compartilhar seus resultados de exames laboratoriais. Essas permissões são necessárias pelo aplicativo para obter suporte para salvar os arquivos no armazenamento externo do dispositivo.  Isso também permitirá que o aplicativo leia os arquivos gerados pelo aplicativo.
            </p>
            <p>
              <strong>Direitos do titular:</strong> o titular do dado tem o direito de obter informações e realizar solicitações junto ao controlador, tais como: (i) confirmação da existência de tratamento; (ii) acesso aos dados; (iii) correção de dados incompletos, inexatos ou desatualizados; (iv) anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com a Lei Geral de Proteção de Dados Pessoais – “LGPD”; (v) portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa, de acordo com a regulamentação da autoridade nacional, observados os segredos comercial e industrial; (vi) eliminação dos dados pessoais tratados com o consentimento do titular; (vii) informação das entidades públicas e privadas com as quais o controlador realizou uso compartilhado de dados; (viii) informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa; e (ix) revogação do consentimento.
            </p>
            <p>
              <strong>Doador:</strong> pessoa física que realiza doação de sangue ou outro material genético para realização de algum exame ou transfusão.
            </p>
            <p>
              <strong>Encarregado/DPO:</strong> pessoa física ou jurídica indicada pelo controlador e operador para atuar como canal de comunicação entre o controlador, os titulares dos dados e a Autoridade Nacional de Proteção de Dados (ANPD).
            </p>
            <p>
              <strong>Eliminação:</strong> exclusão de dado pessoal ou de conjunto de dados pessoais armazenados em banco de dados, independentemente do procedimento empregado.
            </p>
            <p>
              <strong>LGPD:</strong> Lei Geral de Proteção de Dados Pessoais (Lei nº 13.709/2018) que traz regras e disposições sobre o tratamento de dados pessoais, inclusive nos meios digitais, por pessoa natural ou por pessoa jurídica de direito público ou privado, com o objetivo de proteger os direitos fundamentais de liberdade e de privacidade e o livre desenvolvimento da personalidade da pessoa natural.
            </p>
            <p>
              <strong>Operador:</strong> pessoa física ou jurídica, de direito público ou privado, que realiza o tratamento de dados em nome do controlador, sem tomar qualquer decisão sobre como tratar os dados.
            </p>
            <p>
              <strong>Marco Civil da Internet:</strong> Lei 12.965/2014, que estabelece princípios, garantias, direitos e deveres para o uso da Internet no Brasil.
            </p>
            <p>
              <strong>Pseudonimização: </strong> processo a ser aplicado em um dado pessoal para torná-lo pseudonimizado, ou seja, dado pessoal que tenha sido descaracterizado ou codificado de modo que não permita a identificação do titular em um primeiro momento, mas que, após associação com outros dados, leva à sua identificação. Os dados pseudonimizados são aqueles que permitem a associação a um indivíduo a partir de informações mantidas pelo controlador em ambiente separado e seguro, como no caso da segregação da base de dados ou da atribuição de identificadores a indivíduos.
            </p>
            <p>
              <strong>Retenção dos dados pessoais:</strong> período pelo qual os dados pessoais permanecem armazenados mesmo após o término da finalidade do tratamento.
            </p>
            <p>
              <strong>Terceiros:</strong> pessoas físicas ou jurídicas parceiras do grupo Hermes Pardini, prestadores de serviços, médicos ou fornecedores.
            </p>
            <p>
              <strong>Titular:</strong> pessoa física a quem se referem os dados pessoais que são objeto do tratamento.
            </p>
            <p>
              <strong>Tratamento de Dados:</strong> toda operação realizada com dados pessoais, como as que se referem a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração.
            </p>
            <p>
              <strong>Usuário:</strong> pessoa física que navega no site do grupo Hermes Pardini. O Usuário é Titular de dados pessoais.
            </p>
            <p>
              <strong>Vazamento:</strong> divulgação ilícita ou acesso não autorizado a dados pessoais.
            </p>

          </div>

          <h3 className="title">Seções desta Política de Privacidade e Proteção de Dados Pessoais</h3>

          <div className="description">
            <p>
              Esta Política de Privacidade e Proteção de Dados Pessoais informa quais dados pessoais coletamos sobre você, para o que usamos, como e onde armazenamos e com quem compartilhamos. Também define os seus direitos em relação aos seus dados pessoais e quem você pode contatar para obter mais informações ou esclarecimentos a respeito desse tema. Veja abaixo as seções desta Política:
            </p>

            <ol>
              <li>Como coletamos os seus dados pessoais;</li>
              <li>Tipos de dados pessoais que coletamos;</li>
              <li>Como utilizamos seus dados pessoais;</li>
              <li>Fundamentos legais para tratar os seus dados pessoais;</li>
              <li>Compartilhamento de seus dados pessoais;</li>
              <li>Dados de Crianças e Adolescentes;</li>
              <li>Retenção de seus dados pessoais;</li>
              <li>Segurança de seus dados pessoais;</li>
              <li>Gestão de Cookies;</li>
              <li>Seus direitos;</li>
              <li>Alterações nesta Política de Privacidade e Proteção de Dados Pessoais;</li>
              <li>Reclamações, dúvidas e/ou solicitações;</li>
            </ol>
          </div>

          <h5 className="title">1.	Como coletamos os seus dados pessoais </h5>

          <div className="description">
            <p>
              Coletamos seus dados pessoais e dados pessoais sensíveis (em conjunto referidos nesta Política simplesmente como “dados pessoais”) automaticamente quando você visita nosso site, utiliza nossos aplicativos, e-mails ou anúncios. Também coletamos seus dados pessoais durante a prestação de nossos serviços. Assim, a coleta de dados pode advir das seguintes fontes:
            </p>

            <ol type="a">
              <li>
                <strong>Diretamente do titular:</strong> podemos coletar dados pessoais que você nos forneceu quando, por exemplo, você nos contrata para prestação de serviços, quando está na condição de doador (por exemplo, para realizar um exame de sangue), porque seu paciente nos forneceu (por exemplo, se você é médico e seu paciente irá realizar algum exame em algum de nossos laboratórios), ou porque estão publicamente disponíveis. Ainda, podemos coletar dados pessoais de incapazes e relativamente incapazes, como por exemplo crianças, adolescentes, interditados e curatelados, fornecidos pelo próprio titular ou por seus pais ou responsáveis legais.
              </li>
              <li>
                <strong>Via interação com o nosso site e/ou aplicativo:</strong> através de sua interação em nosso site, nosso aplicativo, podemos coletar dados pessoais sobre você para a prestação de serviços (por exemplo, podemos coletar seus dados pessoais para: o agendamento de um exame, divulgar resultados ou registrar uso de medicamentos). Também podemos coletar ou obter dados pessoais sobre você pela maneira como você interage com nosso site e/ou aplicativo, para fornecer informações que acreditamos ser de seu interesse. Ainda, podemos coletar dados referente à geolocalização do dispositivo utilizado para garantir agilidade nos atendimentos e indicar a nossa unidade de atendimento ou colhedor em domicílio mais próximo de você. Por fim, podemos utilizar a sua câmera para que nosso sistema consiga executar o módulo de realidade aumentada com objetivo de melhorar a interação e experiência durante a visualização do resultado no app.
              </li>
              <li>
                <strong>Através de terceiros:</strong> também podemos coletar seus dados pessoais através de parceiros, médicos ou fornecedores. Neste caso, adotamos medidas para garantir que foram cumpridas as regras de privacidade e proteção de dados pessoais, dispostas na LGPD, incluindo a coleta de termo de consentimento, se assim for necessário.
              </li>
              <li>
                <strong>Via sistemas integrados: </strong> através de sistemas integrados entre laboratórios do grupo Hermes Pardini e entre estes e laboratórios conveniados, para realizar exames e/ou checar o histórico de exames e demais informações necessárias para realizar a nossa prestação de serviços.
              </li>
            </ol>

          </div>

          <h5 className="title">2.	Tipos de dados pessoais que coletamos </h5>

          <div className="description">
            <p>
              Clientes e usuários do nosso site/aplicativo: durante a prestação de nossos serviços ou mesmo durante a navegação em nosso site e aplicativo, podemos coletar alguns dados pessoais sobre você, tais como:
            </p>

            <ol type="a">
              <li>
                <strong>Dados	 de identificação:</strong> nome, RG, CPF, idade, sexo, endereço de e-mail, endereço comercial/residencial, telefone, data de nascimento, estado civil, carteirinha do plano, filiação, convênio médico, documentos para fins de identidade (tais como documentos de conselho de classe, carteira nacional de habilitação, número do passaporte e outros documentos oficiais) e imagens de circuito interno de câmeras.
              </li>
              <li>
                <strong>Dados sensíveis:</strong> dados relacionados a sua saúde, relatórios médicos, solicitações médicas, resultados de exames, medicamentos que você utiliza, dados genéticos, dados biométricos, etnia, orientação sexual e religião.
              </li>
              <li>
                <strong>Dados financeiros:</strong> informações sobre pagamento, conta bancária e dados de cartões de crédito.
              </li>
              <li>
                <strong>Dados de navegação:</strong> login e senha de acesso, postagens em nossas mídias sociais, endereço IP, tipo de navegador e idioma, horários de acesso, detalhes de solicitações e de como você usa nossos serviços e de sua interação conosco. Além disso e prezando pela transparência na relação com nossos clientes sinalizamos, também coletamos cookies quando você acessa nosso site e/ou aplicativo, para melhor experiência do usuário, seguindo as regras da nossa Política de Cookies.
              </li>
            </ol>

            <p>
              <strong>Acompanhantes de clientes:</strong> durante a prestação de nossos serviços podemos coletar dados de identificação sobre seus acompanhantes presentes durante a realização de algum exame ou procedimento, tais como: Nome, RG, CPF e imagens de circuito interno de câmeras.
            </p>
            <p>
              <strong>Médicos:</strong> durante a prestação de nossos serviços podemos coletar dados de identificação sobre os médicos responsáveis pelas solicitações dos exames, a saber: nome, especialidade profissional e CRM
            </p>
          </div>

          <h5 className="title">3.	Como utilizamos seus dados pessoais</h5>

          <div className="description">
            <p>
              Coletamos, armazenamos e tratamos seus dados pessoais para diversas finalidades ligadas ao nosso negócio, tais como:
            </p>

            <ol type="a">
              <li>
                Clientes e usuários do nosso site/aplicativo

                <ul>
                  <li>
                    <strong>Dados identificação:</strong> para identificar e confirmar sua identidade para atendimentos, solicitar autorização junto às operadoras de planos de saúde, realizar agendamentos, informar preparos para exames, divulgar produtos e serviços, assegurar o acesso às unidades do grupo Hermes Pardini e atender às determinações legais e regulatórias.
                  </li>
                  <li>
                    <strong>Dados sensíveis:</strong> entender histórico clínico, apoiar na descrição de resultados e direcionamento de diagnósticos e realizar exames.
                  </li>
                  <li>
                    <strong>Dados financeiros:</strong> identificar, agendar e faturar pagamentos e atender questões fiscais, legais e regulatórias.
                  </li>
                  <li>
                    <strong>Dados de navegação:</strong> agendar procedimentos e realizar atendimentos on-line, cumprir determinações legais de coleta de dados pessoais dispostas no Marco Civil da Internet (Lei nº 12.965/2014), promover melhorias na experiência de navegação e realizar análises estatísticas.
                  </li>
                </ul>
              </li>
              <li>
                Dados de navegação:

                <ul>
                  <li>
                    <strong>Dados de identificação:</strong> para fins de identificação e controle de acesso.
                  </li>
                </ul>
              </li>
              <li>
                Médicos

                <ul>
                  <li>
                    <strong>Dados de identificação:</strong> para fins de identificação, confirmação da solicitação médica e registro.
                  </li>
                </ul>
              </li>
            </ol>
          </div>

          <h5 className="title">4.	Fundamentos legais para tratar os seus dados pessoais</h5>

          <div className="description">
            <p>
              A LGPD dispõe que o tratamento de dados pessoais apenas deve ocorrer mediante fundamento legal. Assim, destacamos abaixo as hipóteses legais em que os seus dados pessoais poderão ser tratados, quando estamos na posição de Controlador, aplicando-se de acordo com a categoria dos dados (dados pessoais ou dados pessoais sensíveis):
            </p>

            <ol type="a">
              <li>
                Mediante o fornecimento do seu consentimento para tratamento de seus dados pessoais, como por exemplo, para lhe conceder acesso ao site, aplicativo ou outras plataformas mantidas pelo grupo Hermes Pardini ou receber informações via e-mail sobre seus interesses;
              </li>
              <li>
                Quando existentes legítimos interesses para tratamento de seus dados pessoais como no oferecimento e entrega de nossos serviços para você, bem como para o funcionamento eficaz e lícito de nossa prestação de serviços, desde que tais interesses não sejam superados pelos seus interesses, direitos e liberdades fundamentais;
              </li>
              <li>
                Para o cumprimento de obrigações legais e regulatórias que podem exigir a coleta, armazenamento e compartilhamento de seus dados pessoais e dados pessoais sensíveis, tais como manutenção de registros para fins fiscais ou fornecimento de informações a um órgão público ou entidade reguladora de leis/atividades do objeto social do grupo Hermes Pardini e cumprimento de obrigações de combate à corrupção, lavagem de dinheiro, fraude e condutas irregulares;
              </li>
              <li>
                Para executar eventual contrato, bem como para fornecer nossos serviços a você;
              </li>
              <li>
                Para exercer regularmente nossos direitos em contratos, processos judiciais, administrativos ou arbitrais;
              </li>
              <li>
                Para proteção da vida ou da sua incolumidade física;
              </li>
              <li>
                Para tutelar sua saúde;
              </li>
              <li>
                para proteção de nosso crédito;
              </li>
              <li>
                Para garantir a prevenção à fraude e à sua segurança, nos processos de identificação e autenticação de cadastro em sistemas eletrônicos.
              </li>
            </ol>

            <p>
              <strong>Quando o grupo Hermes Pardini atuar na posição de Operador, a hipótese legal em que os dados pessoais serão tratados será definida pelo Controlador do dado pessoal. </strong>
            </p>
          </div>

          <h5 className="title">5.	Compartilhamento de seus dados pessoais </h5>

          <div className="description">
            <p>
              No decorrer da prestação de nossos serviços, poderemos compartilhar seus dados pessoais com:
            </p>

            <ol type="a">
              <li>
                Laboratórios, hospitais, profissionais médicos, e unidades de atendimento do grupo Hermes Pardini para, por exemplo, exercer regularmente algum direito ou executar devidamente os serviços contratados;
              </li>
              <li>
                Terceiros que nos prestam serviços nas condições de operadores de tratamento de dados pessoais;
              </li>
              <li>
                Autoridades competentes (incluindo tribunais e autoridades que nos regulam);
              </li>
              <li>
                Empresas de tecnologia que fazem a gestão dos nossos sistemas integrados ou responsáveis pelo armazenamento e garantia de segurança no tratamento de seus dos dados pessoais; internamente para áreas que necessitam ter acesso aos dados pessoais, tais como: área técnica responsável pelos exames, área de atendimento ao cliente e área jurídica para cumprir alguma obrigação legal regulatória ou exercício regular dos nossos direitos.
              </li>
            </ol>

            <p>De qualquer forma, o grupo Hermes Pardini exigirá que os terceiros acima indicados: </p>

            <ol type="a">
              <li>Comprometam-se a cumprir as leis de proteção de dados e os princípios desta Política;</li>
              <li>Somente processem os Dados Pessoais para os fins descritos nesta Política;</li>
              <li>Implementem medidas de segurança técnica e organizacional apropriadas projetadas para proteger a integridade e confidencialidade de seus Dados Pessoais.</li>
            </ol>

            <p>
              Ao realizar interação com nosso site ou aplicativo, que eventualmente permitam compartilhar conteúdo com outros usuários, os dados pessoais e informações que você publicar podem ser lidas, coletadas e usadas por outros usuários do site ou aplicativo. Temos pouco ou nenhum controle sobre esses outros usuários e, portanto, não podemos garantir que qualquer informação ou dado pessoal que você forneça nesse contexto será tratado de acordo com esta Política de Privacidade e Proteção de Dados Pessoais.
            </p>
            <p>
              Além disso, ao realizar solicitações de atendimento, agendamentos, pré-atendimentos e falar conosco pelo nosso site, seus dados pessoais serão compartilhados com nossos atendentes e prestadores de serviços de infraestrutura dos sistemas geridos pelo grupo Hermes Pardini, os quais são treinados e capacitados a tratar seus dados pessoais de forma ética e em linha com esta Política. Outros compartilhamentos podem ser realizados com a finalidade de atender as suas solicitações e prestar devidamente os serviços contratados. Para informações detalhadas sobre os nomes dos terceiros com os quais compartilhamos seus dados pessoais, entre em contato com o(a) nosso(a) DPO/Encarregado(a) através do e-mail privacidade@grupopardini.com.br.
            </p>
          </div>

          <h5 className="title">6.	Dados de Crianças e Adolescentes </h5>

          <div className="description">
            <p>
              Durante a prestação de serviços, o grupo Hermes Pardini poderá coletar dados pessoais de crianças e adolescentes e garantirá que o tratamento sempre ocorra no melhor interesse da criança e do adolescente.
            </p>

            <ul>
              <li>
                <strong>Crianças:</strong> O tratamento de dados pessoais de crianças será realizado mediante consentimento específico e em destaque de pelo menos um de seus pais ou responsável legal. Em caso de urgência/emergência, a coleta e tratamento dos dados pessoais da criança será realizado imediatamente para proteção de sua vida e, posteriormente, será comunicado a um de seus pais ou responsável legal.
                <br />
                As mesmas disposições serão aplicáveis aos dados pessoais de interditados e curatelados.
              </li>
              <li>
                <strong>Adolescentes:</strong> Em relação ao adolescente, o tratamento de seus dados pessoais poderá ocorrer independentemente do consentimento de um dos pais ou responsável legal, desde que presente outro fundamento legal que autorize o tratamento, nos termos da LGPD.
              </li>
            </ul>
          </div>

          <h5 className="title">7.	Retenção de seus dados pessoais </h5>

          <div className="description">
            <p>
              Armazenamos e mantemos seus dados pessoais de forma segura em data centers localizados no Brasil, em conformidade com a legislação aplicável e pelo período necessário ou permitido em vista das finalidades para as quais os dados pessoais foram coletados, conforme exposto nesta Política de Privacidade e Proteção de Dados Pessoais.
            </p>

            <p>
              Os critérios utilizados para determinar os períodos de retenção incluem, mas não se limitam a:
            </p>

            <ol type="I">
              <li>
                Duração do nosso relacionamento com você;
              </li>
              <li>
                Enquanto válido seu consentimento, nas hipóteses aplicáveis;
              </li>
              <li>
                Diante de eventual obrigação legal ou regulatória que exija a manutenção dos dados pessoais;
              </li>
              <li>
                Quando necessário para atividade ou serviços relevantes;
              </li>
              <li>
                Para atender prazos prescricionais aplicáveis, conforme previsto em lei ou regulamento. 
              </li>
            </ol>
          </div>

          <h5 className="title">8.	Segurança de seus dados pessoais </h5>

          <div className="description">
            <p>
              Estamos comprometidos em proteger a sua privacidade e seus dados. Para isso, adotamos medidas de segurança, técnicas e administrativas aptas a proteger os seus dados pessoais de acessos não autorizados e de situações acidentais ou ilícitas de destruição, perda, alteração, comunicação ou qualquer forma de tratamento inadequado ou ilícito, o que inclui, mas não se limita à:
            </p>

            <ol type="a">
              <li>
                Limitação do acesso a dados pessoais por parte dos colaboradores, prestadores de serviços e visitantes, restringindo-o apenas nos limites da necessidade e finalidade de tratamento dos dados pessoais;
              </li>
              <li>
                Garantia de que todos os nossos colaboradores cumprem esta Política, são constantemente treinados e capacitados para realizar procedimentos adequados para o correto tratamento de seus dados pessoais;
              </li>
              <li>
                Utilização de tecnologias concebidas para proteger seus dados pessoais durante o compartilhamento com empresas terceiras;
              </li>
              <li>
                Utilização de medidas técnicas capazes de inibir/mitigar riscos de eventuais maliciosos em nossos sistemas;
              </li>
              <li>
                Adoção de sistemas estruturados de forma a atender aos requisitos de segurança, aos padrões de boas práticas e de governança e aos princípios gerais previstos na LGPD;
              </li>
              <li>
                Manutenção recorrente do banco de dados;
              </li>
              <li>
                Utilização de ferramenta de categorização do gartnes, efetuando backup na nuvem com conteúdo criptografado;
              </li>
              <li>
                Utilização de tecnologias concebidas para proteger os dados pessoais durante a sua transmissão, como encriptação SSL dos dados que você fornece em determinadas partes do nosso site e utilização de segurança adequada para proteger os dados pessoais recebidos.
              </li>
            </ol>

            <p>
              Também aplicamos processos e medidas, padrão da indústria para a detecção e resposta a tentativas de violação dos nossos sistemas. Entretanto, não existe um método de transmissão pela Internet ou um método de armazenamento eletrônico que seja 100% seguro. Por conseguinte, não podemos garantir a segurança absoluta das suas informações, apesar de serem tomadas todas as precauções necessárias e exigidas pelos órgãos competentes.
            </p>
            <p>
              A Internet, dada a sua natureza, é um fórum público e por isso recomendamos que você tenha cautela ao divulgar informações online. Frequentemente, você se encontra na melhor posição para proteger a si mesmo online. Você é responsável pela proteção do seu nome de usuário e senha contra o acesso de terceiros, assim como pela escolha de senhas seguras.
            </p>
          </div>

          <h5 className="title">9.	Gestão de Cookies </h5>

          <div className="description">
            <p>Sempre que você utilizar nosso site, coletaremos cookies para melhorar sua experiência de navegação. Você poderá se opor a coleta de alguns tipos de cookies através do nosso site, bastando que desative esta opção no seu próprio navegador ou aparelho. </p>
            <p>A desativação dos cookies, no entanto, pode afetar a disponibilidade de algumas ferramentas e funcionalidades do site, comprometendo seu correto e esperado funcionamento. Outra consequência possível é remoção das suas preferências que eventualmente tiverem sido salvas, prejudicando sua experiência. </p>
          </div>

          <h5 className="title">10.	Seus Direitos </h5>

          <div className="description">
            <p>
              Você possui vários direitos em relação aos seus dados pessoais, nos termos da LGPD. Para tanto, implementamos controles adicionais de transparência e acesso em nossa área de Privacidade para disponibilizar aos usuários o acesso livre e gratuito a esses direitos. Neste contexto, você tem o direito a:
            </p>

            <ol type="a">
              <li>
                Confirmação de que estamos tratando seus dados pessoais;
              </li>
              <li>
                Acessar os dados pessoais que tratamos sobre você;
              </li>
              <li>
                Solicitar a alteração ou atualização de seus dados pessoais quando estiverem incorretos, incompletos ou inexatos;
              </li>
              <li>
                Solicitar que os dados pessoais que você entenda como desnecessários, excessivos ou tratados em desconformidade com a LGPD sejam anonimizados, bloqueados ou eliminados, desde que permitido pelas legislações/regulamentos que estejam relacionados ao objeto social do grupo HERMES PARDINI;
              </li>
              <li>
                Se opor ao tratamento de dados pessoais, quando não tivermos mais necessidade legítima ou legal de tratá-los;
              </li>
              <li>
                Solicitar a transmissão dos dados pessoais que tratamos sobre você para outro fornecedor;
              </li>
              <li>
                Solicitar informações das entidades públicas e privadas com as quais compartilhamos seus dados pessoais;
              </li>
              <li>
                Revogar o consentimento concedido, solicitar a eliminação dos dados pessoais tratados com base em consentimento, bem como de ter acesso a informações sobre a possibilidade de você não fornecer o consentimento e as respectivas consequências da negativa; e
              </li>
              <li>
                Solicitar a revisão do tratamento de dados pessoais com base em decisões automatizadas.
              </li>
            </ol>

            <p>
              Se você tiver alguma dúvida, observação, solicitação, reclamação ou revisão sobre a coleta ou o uso de seus dados pessoais ou sobre esta Política de Privacidade e Proteção de Dados Pessoais, você pode entrar em contato com o(a) nosso(a) DPO/Encarregado(a), através do envio de e-mail para o endereço eletrônico privacidade@grupopardini.com.br
            </p>
          </div>

          <h5 className="title">11.	Alterações nesta Política de Privacidade e Proteção de Dados Pessoais </h5>

          <div className="description">
            <p>A presente Política de Privacidade e Proteção de Dados Pessoais poderá ser alterada a qualquer tempo. Portanto, recomendamos que você reveja esta Política de tempos em tempos para ser informado sobre como estamos protegendo suas informações. </p>
            <p>Todas as alterações serão comunicadas por meio de um aviso em destaque na tela inicial do nosso site/aplicativo ou por meio de qualquer outra forma de comunicação com você. </p>
          </div>

          <h5 className="title">12.	Reclamações, dúvidas e/ou solicitações </h5>

          <div className="description">
            <p>Se você não estiver satisfeito com a maneira como tratamos os seus dados pessoais ou em caso de qualquer dúvida, reclamação, preocupação ou solicitações relacionadas a sua privacidade e proteção de seus dados pessoais, você pode entrar em contato com o(a) nosso(a) DPO/Encarregado(a), através do envio de e-mail para o endereço eletrônico privacidade@grupopardini.com.br </p>
          </div>

        </div>
      </section>
    </>
  )
}