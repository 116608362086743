import './AreaCobertura.css';
import { HashLink as Link } from 'react-router-hash-link';

export default function AreaCobertura() {
    return (
        <>
          <section id="cobertura">
            <div className="container-fluid">
                <span>Área de cobertura</span>
                <h3>Se tem SaúdeMob, tem <span> atendimento onde você quiser</span></h3>
                <Link to="/area-de-cobertura#root" className="btn btn-primary" title="veja as regiões atendidas">Veja as regiões atendidas</Link>
            </div>
            </section>

        {/* <section id="consulte">
            <div className="container">
                <figure>
                    <img src={MapImg} alt="mapa" />
                </figure>
                <div className="form-consulte">
                    <p>Consulte se o CEP de onde você está faz parte das regiões atendidas</p>

                    <input type="text" placeholder="Digite seu CEP" /> <input type="submit" value="Buscar" />
                </div>
            </div>
        </section> */}
        </>
    )
}