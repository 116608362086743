import notredame from '../../assets/images/convenios/logo-notredame.png'
import bradesco from '../../assets/images/convenios/logo-bradesco.png'
import amil from '../../assets/images/convenios/logo-amil.png'
import sulamerica from '../../assets/images/convenios/logo-sulamerica.png'
import portoseguro from '../../assets/images/convenios/logo-porto-seguro.png'
import unimed from '../../assets/images/convenios/logo-unimed.png'
import './Convenios.css';

import Slider from "react-slick";

import { whatsAppLink } from '../../config/whatsAppLink';

export default function ListaConvenios() {
    const settings = {
        dots: true,
        className: "center",
        centerMode: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <>
            <section id="convenios">
                <div className="container">
                    <div className="row desktop-version">
                        <div className="col-6 col-md-2"><img src={notredame} alt="Notredame" /></div>
                        <div className="col-6 col-md-2"><img src={bradesco} alt="Bradesco" /></div>
                        <div className="col-6 col-md-2"><img src={amil} alt="Amil" /></div>
                        <div className="col-6 col-md-2"><img src={sulamerica} alt="Sul América" /></div>
                        <div className="col-6 col-md-2"><img src={portoseguro} alt="Porto Seguro" /></div>
                        <div className="col-6 col-md-2"><img src={unimed} alt="Unimed" /></div>
                        <div className="col-12">
                            <a href={whatsAppLink} className="btn btn-primary">Confira outros planos com nosso Atendimento</a>
                        </div>
                    </div>

                    <div className="row mobile-version">
                        <Slider {...settings}>
                            <div className="col-12">
                                <img src={notredame} alt="Notredame" />
                                <img src={bradesco} alt="Bradesco" />
                            </div>
                            <div className="col-12">
                                <img src={amil} alt="Amil" />
                                <img src={sulamerica} alt="Sul América" />
                            </div>
                            <div className="col-12">
                                <img src={portoseguro} alt="Porto Seguro" />
                                <img src={unimed} alt="Unimed" />
                            </div>
                        </Slider>
                        <div className="col-12">
                            <a href={whatsAppLink} className="btn btn-primary">Confira outros planos</a>
                        </div>
                    </div>

                   
                </div>
            </section>
        </>
    )
}