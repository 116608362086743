import './Faq.css';

export default function FAQConvenios() {
    return (
        <>
            <section id="perguntas-frequentes">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-12">
                        <h3>Perguntas frequentes</h3>
                        <p>Está com dúvidas? Consulte nossa FAQ</p>
                        <div className="accordion" id="accordionExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOnec">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOnec" aria-expanded="false" aria-controls="collapseOnec">
                                    Como saber se meu plano cobre o SaúdeMob?
                                </button>
                                </h2>
                                <div id="collapseOnec" className="accordion-collapse collapse" aria-labelledby="headingOnec" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Você pode verificar aqui no nosso site, pelo atendimento via WhatsApp ou diretamente com o seu convênio.
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingTwoc">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwoc" aria-expanded="false" aria-controls="collapseTwoc">
                                    Meu convênio não cobre o SaúdeMob. Posso pagar pelo atendimento e solicitar reembolso ao meu plano de saúde?
                                </button>
                                </h2>
                                <div id="collapseTwoc" className="accordion-collapse collapse" aria-labelledby="headingTwoc" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    O atendimento pelo SaúdeMob pode ser pago, mas as regras de reembolso devem ser verificadas antecipadamente com o seu plano de saúde.
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingThreec">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThreec" aria-expanded="false" aria-controls="collapseThreec">
                                    Quais são as formas de pagamento?
                                </button>
                                </h2>
                                <div id="collapseThreec" className="accordion-collapse collapse" aria-labelledby="headingThreec" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Os atendimentos particulares podem ser pagos no cartão de crédito, via pix ou boleto bancário.
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}