import './Faq.css';

export default function FAQTestes() {
    return (
        <>
            <section id="perguntas-frequentes">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-12">
                        <h3>Perguntas frequentes</h3>
                        <p>Está com dúvidas? Consulte nossa FAQ</p>
                        <div className="accordion" id="accordionExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOnet">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOnet" aria-expanded="false" aria-controls="collapseOnet">
                                    Preciso de pedido médico para realizar testes?
                                </button>
                                </h2>
                                <div id="collapseOnet" className="accordion-collapse collapse" aria-labelledby="headingOnet" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    O pedido médico só é exigido se o teste for realizado por meio do seu convênio.
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingTwot">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwot" aria-expanded="false" aria-controls="collapseTwot">
                                    Onde posso consultar os resultados dos meus testes?
                                </button>
                                </h2>
                                <div id="collapseTwot" className="accordion-collapse collapse" aria-labelledby="headingTwot" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Pelo site do SaúdeMob, clique em Resultados de Exames, selecione o seu Estado e nós te direcionamos para o portal do laboratório parceiro. Realize o acesso com os dados do seu protocolo para ver os laudos.
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingThreet">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThreet" aria-expanded="false" aria-controls="collapseThreet">
                                    Em quanto tempo o resultado dos meus testes ficará disponível?
                                </button>
                                </h2>
                                <div id="collapseThreet" className="accordion-collapse collapse" aria-labelledby="headingThreet" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    O prazo varia de acordo com o tipo de teste. Durante o agendamento, o nosso time de atendimento informa o prazo do procedimento que você vai realizar.
                                </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}