import './Faq.css';

export default function FAQ() {
    return (
        <>
            <section id="perguntas-frequentes">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-12">
                            <h3>Perguntas frequentes</h3>
                            <p>Está com dúvidas? Consulte nossa FAQ</p>
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOnes">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOnes" aria-expanded="false" aria-controls="collapseOnes">
                                            Quais são os serviços oferecidos?
                                        </button>
                                    </h2>
                                    <div id="collapseOnes" className="accordion-collapse collapse" aria-labelledby="headingOnes" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            O SaúdeMob oferece diversos tipos de testes e exames e realiza a aplicação de vacinas, atendendo todas as faixas etárias, no seu local de preferência.
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingThrees">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThrees" aria-expanded="false" aria-controls="collapseThrees">
                                            Como comprar os procedimentos?
                                        </button>
                                    </h2>
                                    <div id="collapseThrees" className="accordion-collapse collapse" aria-labelledby="headingThrees" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            Entre em contato com a nossa equipe de atendimento via WhatsApp. Lá você pode enviar o seu pedido médico para receber um orçamento, realizar a compra e agendar a visita do nosso laboratório móvel.
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingFours">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFours" aria-expanded="false" aria-controls="collapseFours">
                                            O SaúdeMob cobra taxa para o atendimento?
                                        </button>
                                    </h2>
                                    <div id="collapseFours" className="accordion-collapse collapse" aria-labelledby="headingFours" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            Cobramos uma taxa referente aos custos de deslocamento da nossa equipe até o seu local. Consulte os valores de acordo com a sua região.                                </div>
                                    </div>
                                </div> */}
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingFives">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFives" aria-expanded="false" aria-controls="collapseFives">
                                            Como realizo o pagamento dos meus procedimentos?
                                        </button>
                                    </h2>
                                    <div id="collapseFives" className="accordion-collapse collapse" aria-labelledby="headingFives" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            Após o atendimento, você realiza o pagamento diretamente ao profissional de saúde.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingSixs">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSixs" aria-expanded="false" aria-controls="collapseSixs">
                                            Como recebo a Nota Fiscal dos serviços contratados?
                                        </button>
                                    </h2>
                                    <div id="collapseSixs" className="accordion-collapse collapse" aria-labelledby="headingSixs" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            A Nota Fiscal é enviada por e-mail.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingSevens">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSevens" aria-expanded="false" aria-controls="collapseSevens">
                                            Como agendar o atendimento com o plano de saúde?
                                        </button>
                                    </h2>
                                    <div id="collapseSevens" className="accordion-collapse collapse" aria-labelledby="headingSevens" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            Você pode verificar a cobertura do seu convênio diretamente com a operadora ou com o nosso atendimento. Se o tipo de serviço for coberto, o nosso atendimento realiza o agendamento e solicita as devidas autorizações à operadora do plano de saúde.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}