const agendarExame = `
  Sabia que você pode agendar o seu exame de onde você estiver*? <br> <br>
  Com o SaúdeMob você garante acesso à saúde à toda sua família. Muito mais do que um atendimento domiciliar, o SaúdeMob é o seu laboratório, onde você estiver*. <br> <br>
  Tenha um atendimento exclusivo de forma rápida, segura e acessível e com a qualidade, precisão, responsabilidade e rigor técnico de um laboratório.
`;

export const exames = [
  {
    id: "polissonografia",
    titulo: "Polissonografia",
    descricao: "Sonolência diurna excessiva, alteração do ritmo cardíaco, questões respiratórias como roncos e apneia e síndrome da pernas inquietas podem ser sintomas de distúrbio do sono. Para esses casos, o médico pede ao paciente que faça o exame de Polissonografia e assim investigar possíveis distúrbios do sono.",
    perguntas: [
      {
        pergunta: "Para que serve o exame de Polissonografia?",
        resposta: "A Polissonografia não é um exame de rotina, mas geralmente é solicitado quando o médico suspeita de algum distúrbio do sono no paciente. Por este motivo, a Polissonografia é indicada em casos de sonolência excessiva e incontrolável."
      },
      {
        pergunta: "Como é feito o exame de Polissonografia?",
        resposta: "Durante o exame de Polissonografia, o paciente é monitorado com a ajuda de eletrodos que medem a quantidade de oxigênio que é inalado durante o sono. Ou seja, o exame é feito enquanto o paciente dorme, e dura, em média, 8 horas."
      },
      {
        pergunta: "Como me preparar para o exame de Polissonografia?",
        resposta: "A recomendação é que o paciente evite o consumo de café, bebidas energéticas ou alcoólicas 24 horas antes da realização do exame de Polissonografia. Também é importante evitar o uso de cremes e gel que podem dificultar a fixação dos eletrodos."
      },
      {
        pergunta: "Existe alguma contraindicação para o exame de Polissonografia?",
        resposta: "Não existe nenhuma contraindicação para a realização do exame de Polissonografia. Vale lembrar que o exame de Polissonografia é indolor, não invasivo e não provoca efeitos colaterais. O exame de Polissonografia pode ser feito em todas as idades."
      },
      {
        pergunta: "Como agendar o exame de Polissonografia?",
        resposta: agendarExame
      }
    ],
    whatsapp: "https://api.whatsapp.com/send?phone=553140202567&text=Ol%C3%A1%2C%20gostaria%20de%20agendar%20a%20aplica%C3%A7%C3%A3o%20m%C3%B3vel%20da%20Vacina%20DTPA%2C%20pode%20me%20ajudar%3F%22"
  },
  {
    id: "ECG",
    titulo: "Eletrocardiograma",
    descricao: "O eletrocardiograma é um exame feito a partir de eletrodos posicionados no tórax e extremidades do paciente.  Através do eletrocardiograma é possível avaliar a atividade elétrica do coração pontualmente. Pode ser solicitado com o objetivo de acompanhar o efeito de medicamentos para arritmia ou o funcionamento do marcapasso.",
    perguntas: [
      {
        pergunta: "Como é feito o Eletrocardiograma?",
        resposta: "Para realizar o eletrocardiograma, o médico precisa colocar alguns eletrodos no corpo do paciente a fim de monitorar os impulsos elétricos de diferentes áreas do coração através de gráficos. Trata-se de exame de rápida realização, normalmente com tempo inferior a 05 minutos."
      },
      {
        pergunta: "Como devo me preparar para o eletrocardiograma?",
        resposta: "Não é necessário nenhum tipo de preparo, interrupção de medicação ou jejum para a realização do exame eletrocardiograma. Solicita-se a retirada de equipamentos que podem causar interferência como celulares e relógios."
      },
      {
        pergunta: "Quais são as contraindicações do exame Eletrocardiograma?",
        resposta: "Não existem contraindicações."
      },
      {
        pergunta: "Como marcar o exame Eletrocardiograma?",
        resposta: agendarExame
      }
    ],
    whatsapp: "https://api.whatsapp.com/send?phone=553140202567&text=Ol%C3%A1%2C%20gostaria%20de%20agendar%20a%20aplica%C3%A7%C3%A3o%20m%C3%B3vel%20da%20Vacina%20da%20Dengue%2C%20pode%20me%20ajudar%3F%22"
  }
];