import './Pilares.css';
import Slider from "react-slick";

export default function Pilares() {
    const settings = {
        dots: true,
        className: "center",
        centerMode: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <>
            <section id="pilares">
                <div className="container">
                    <div className="row">
                        <span>Nossos pilares</span>
                        <h3>Para nós, o seu tempo e a sua saúde valem muito</h3>
                    </div>

                    <div className="row desktop-version">
                        <div className="col-md-4">
                            <div className="card">
                                <h4>Expertise</h4>
                                <p>Oferecemos a tecnologia dos melhores laboratórios e profissionais capacitados para cuidar da sua saúde.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card">
                                <h4>Cuidado</h4>
                                <p>Acolhemos cada pessoa de uma forma única. Cuidamos de você e de toda a sua família com segurança e conforto.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card">
                                <h4>Conveniência</h4>
                                <p>Simplificamos todas as etapas e vamos a qualquer lugar para que você possa cuidar da saúde no seu ritmo.</p>
                            </div>
                        </div>
                    </div>

                    <div className="row mobile-version">
                        <Slider {...settings}>
                            <div className="col-md-4">
                                <div className="card">
                                    <h4>Expertise</h4>
                                    <p>Oferecemos a tecnologia dos melhores laboratórios e profissionais capacitados para cuidar da sua saúde.</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card">
                                    <h4>Cuidado</h4>
                                    <p>Acolhemos cada pessoa de uma forma única. Cuidamos de você e de toda a sua família com segurança e conforto.</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card">
                                    <h4>Conveniência</h4>
                                    <p>Simplificamos todas as etapas e vamos a qualquer lugar para que você possa cuidar da saúde no seu ritmo.</p>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </section>
        </>
    )
}