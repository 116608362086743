
import { useLocation, Navigate } from 'react-router-dom';
import { useEffect } from 'react';

import Sobre from '../../components/Sobre/Sobre';
import './Vacina-detalhe.css';

import imgExames from '../../assets/images/exames.png';
import imgSobreMobile from '../../assets/images/sobre-mobile.png';

import { vacinas } from "../../config/listagem-vacinas";

export default function VacinaDetalhe() {
  const location = useLocation();
  const vacinaAtual = vacinas.find(item => ("/vacinas/" + item.id) === location.pathname);
  
  useEffect(() => {
    if(vacinaAtual) {
      const title = vacinaAtual.titulo + " | Saúde Mob";
      document.title = title;

      const el = document.querySelector("meta[name='description']");
      if(el) el.setAttribute('content', vacinaAtual.descricao);
      window.scrollTo(0, 0);
    }
  }, [vacinaAtual])
  
  if (!vacinaAtual) {
    return <Navigate to='/vacinas' />
  }

  const listaDePerguntasVacina = vacinaAtual.perguntas.map((item, i) =>
    <div className="perguntas" key={i}>
      <h4>{item.pergunta}</h4>
      <p dangerouslySetInnerHTML={{ __html: item.resposta }}></p>
    </div>
  );

  return (
    <>
      <section id="vacina-detalhes" className="intro-vacinas">
        <div className="container">
          <h1>{vacinaAtual.titulo}</h1>
          <p>{vacinaAtual.descricao}</p>
        </div>
      </section>

      <section id="perguntas-vacina">
        <div className="container">
          {listaDePerguntasVacina}
        </div>

        <div className="d-flex align-items-center justify-content-center w-100">
          <a href={`${location.pathname}#footer`} className="btn-primary" title="Agende agora">Agende agora mesmo o seu exame pelo whatsapp</a>
        </div>
      </section>

      <div id="wrapper-saudemob">
        <div className="container">
          <h1>Com a Saúde Mob você terá:</h1>

          <div className='d-flex align-items-center justify-content-center'>
            <ul className='lista'>
              <li>Equipe especializada e pronta para te atender no local de sua preferência;</li>
              <li>Evite filas e exposição;</li>
              <li>Agendamento rápido, via whatsapp;</li>
              <li>Resultados online;</li>
              <li>Atendimento particular com planos de saúde.</li>
            </ul>
          </div>

          <strong className='text-center'>SaúdeMob, acesso à saúde para quem precisa, onde estiver*.</strong>
          <p className='requisitos mt-3 mb-4'>*De acordo com os requisitos dispostos na RDC 302/2005</p>
        
          <div className="d-flex align-items-center justify-content-center w-100 pb-5">
            <a href="https://psc.hermespardini.com.br/AgendamentoOnline/login" target="_blank" className="btn-primary" title="Agende agora" rel="noreferrer">Agende agora</a>
          </div>
        </div>
      </div>

      <Sobre title="Sobre o SaúdeMob" image={imgExames} imagemobile={imgSobreMobile} theme="sobre-exames" />
    </>
  );
}