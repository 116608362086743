import React, { useEffect } from 'react';
import Intro from '../../components/Intro/Intro';
import './QuemSomos.css';
import imgSobre1 from '../../assets/images/img-sobre1.png'
import imgSobre2 from '../../assets/images/img-sobre2.png'
import Diferenciais from '../../components/Diferenciais/Diferenciais';
import Pilares from '../../components/Pilares/Pilares';


export default function QuemSomos() {
  useEffect(() => {
    document.title = "Quem Somos | Saúde Mob";
  }, []);

  return <div id="page-sobre">

    <Intro pageName="QuemSomos" />

    <section id="quem-somos" className="txt-featured img-left-txt-right">
      <div className="container">
        <div className="row row-reverse">
          <div className="col-12 col-md-6 col-img">
            <img src={imgSobre1} alt="Sobre" className="img-fluid" />
          </div>
          <div className="col-12 col-md-6 col-xl-4 col-txt">
            <h3>Acolhimento de laboratório de alto padrão</h3>
            <p>Levamos a você um atendimento exclusivo com toda a qualidade de um laboratório que trabalha com precisão, responsabilidade e rigor técnico. Tudo isso de forma rápida, segura e acessível.</p>
          </div>
        </div>
      </div>
    </section>

    <section className="txt-featured img-right-txt-left">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 col-xl-4 offset-xl-2 col-txt">
            <h3>Referência em medicina preventiva e diagnóstica</h3>
            <p>O SaúdeMob é uma solução inovadora do Grupo Pardini, uma empresa com mais de   60 anos de atuação e precursora do mercado de apoio laboratorial, que conta com cerca de 6 mil laboratórios parceiros.</p>
          </div>
          <div className="col-12 col-md-6 col-img">
            <img src={imgSobre2} alt="Sobre" className="img-fluid" />
          </div>

        </div>
      </div>
    </section>

    <Pilares />

    <Diferenciais />

    <div className="agende-agora">
      <a href="https://psc.hermespardini.com.br/AgendamentoOnline/login" target="_black" className="btn btn-primary" title="Agende agora">
        Agende agora
      </a>
    </div>


  </div>;
}
