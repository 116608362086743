import { useEffect } from "react";
import './TermosDeUso.css';

export default function TermosDeUso() {
  useEffect(() => {
    document.title = "Termos de Uso | Saúde Mob";
  }, []);

  return (
    <>
      <section id="termosDeUso">
        <div className="container">
          <h1>Termos de Uso</h1>
        </div>
      </section>
      <section id="intro">
        <div className="container">
          <div className="description">
            <p>
              A seguir estão descritas as regras aplicáveis à utilização do Saúde Mob e seus serviços – disponibilizado pelo Instituto Hermes Pardini S/A, inscrita no CNPJ nº 19.378.769/0001-76 e sediado na Rua Aimorés, nº 66, bairro Funcionários, Belo Horizonte, Minas Gerais, CEP 30.140-920, único e exclusivo proprietário do domínio www.hermespardini.com.br.
            </p>

            <p>
              Ao realizar o acesso ao Saúde Mob, o usuário se submeterá automaticamente às regras e condições destes Termos de Uso.
            </p>

            <p>
              O uso do Saúde Mob deve ser feito em caráter pessoal e intransferível. Não é permitido compartilhamento dos dados de acesso ao Portal do Conhecimento em qualquer site ou ambiente virtual.
            </p>

            <p>
              O Instituto Hermes Pardini S/A poderá, sem prévio aviso, bloquear e cancelar o acesso ao Saúde Mob quando verificar que o usuário praticou algum ato ou mantenha conduta que (i) viole as leis e regulamentos federais, estaduais e/ou municipais, (ii) contrarie as regras destes Termos de Uso, ou (iii) viole os princípios da moral e dos bons costumes.
            </p>

            <p>
              O usuário autoriza o Instituto Hermes Pardini S/A, ou terceiros por ela indicados, a utilizar, por prazo indeterminado, as informações fornecidas durante o uso do Saúde Mob para fins estatísticos e envio de material publicitário, newletters, informes etc.
            </p>

            <p>
              O Instituto Hermes Pardini S/A se reserva do direito de incluir, excluir ou alterar os conteúdos e funcionalidades do Saúde Mob, bem como suspendê-lo temporariamente ou cancelá-lo, a qualquer momento, independentemente de aviso-prévio ao usuário. Da mesma forma, poderá modificar estes Termos de Uso, cuja versão mais recente estará sempre disponível para consulta no próprio Saúde Mob.
            </p>

            <p>
              <strong>O INSTITUTO HERMES PARDINI S/A SE EXIME DE TODA E QUALQUER RESPONSABILIDADE PELOS DANOS E PREJUÍZOS DE QUALQUER NATUREZA QUE POSSAM DECORRER DO ACESSO, INTERCEPTAÇÃO, ELIMINAÇÃO, ALTERAÇÃO, MODIFICAÇÃO OU MANIPULAÇÃO, POR TERCEIROS NÃO AUTORIZADOS, DOS DADOS DO USUÁRIO DURANTE A UTILIZAÇÃO DO PORTAL DO CONHECIMENTO.</strong>
            </p>

            <p>
              As informações solicitadas ao Usuário no momento do acesso e uso do Saúde Mob serão utilizadas pelo Instituto Hermes Pardini S/A somente para os fins previstos nestes Termos de Uso e em nenhuma circunstância, tais informações serão cedidas ou compartilhadas com terceiros, exceto por ordem judicial ou de autoridade competente.
            </p>
          </div>

          <h3 className="title">Do uso do Portal do Saúde Mob</h3>

          <div className="description">
            <ol>
              <li>
                O início de utilização do Saúde Mob implica em aceitar todos os termos e condições deste Termo. Caso o usuário não concorde com os termos e condições estipulados neste termo de uso, deverá interromper imediatamente a utilização do Saúde Mob.
              </li>
              <li>
                <strong>Direitos de Propriedade:</strong> O usuário reconhece expressamente que o Saúde Mob, assim como os logotipos, marcas, insígnias, símbolos, sinais distintivos, manuais, documentação técnica associada e quaisquer outros materiais correlatos ao Saúde Mob, constituem, conforme o caso, direitos autorais, segredos comerciais, e/ou direitos de propriedade do Instituto Hermes Pardini S/A, sendo tais direitos protegidos pela legislação nacional e internacional aplicável à propriedade intelectual e aos direitos autorais, especialmente pelo que contém as Leis números 9.609 e 9.610 de 19/12/1998. Fica expressamente vedado ao usuário, em relação ao Saúde Mob: ceder, doar, alugar, vender, arrendar, emprestar, reproduzir, modificar, adaptar, traduzir, disponibilizar o acesso de terceiros, via on-line, acesso remoto ou de outra forma; incorporar a outros sistemas ou programas, próprios ou de terceiros; oferecer em garantia ou penhor; alienar ou transferir, total ou parcialmente, a qualquer título, de forma gratuita ou onerosa; decompilar, mudar a engenharia (reengenharia), enfim, dar qualquer outra destinação ao Saúde Mob – ou parte dele – que não seja a disposta neste Termo.
              </li>
            </ol>
          </div>

          <h3 className="title">Dos direitos e deveres do usuário</h3>

          <div className="description">
            <ol>
              <li>
                O Usuário será responsável pela correta e idônea utilização do Login e Senha, de seu uso exclusivo durante a utilização do Saúde Mob.
              </li>
              <li>
                O Usuário deverá providenciar, por conta própria, seu acesso ao Saúde Mob e os requisitos mínimos para o funcionamento do mesmo.
              </li>
              <li>
                O Usuário se compromete a comunicar imediatamente ao Instituto Hermes Pardini S/A a eventual perda ou roubo de sua senha de acesso, assim como qualquer risco de acesso a ela por terceiros.
              </li>
              <li>
                Os dados pessoais são de total responsabilidade do Usuário, arcando com sanções civis e penais que eventualmente gerarem no uso indevido do documento de outrem. Lembrando que estes dados deverão ser preenchidos com responsabilidade no Cadastro de Usuário.
              </li>
            </ol>
          </div>

          <h3 className="title">Dos dados pessoais tratados</h3>

          <div className="description">
            <p>
              O Instituto Hermes Pardini S/A, durante a utilização do Saúde Mob, fará o tratamento dos seguintes dados pessoais de seus usuários:
            </p>

            <ol>
              <li>
                <strong>Pacientes:</strong> nome completo, nome social, nome da mãe, foto, sexo, data de nascimento, CPF, e-mail, telefone, endereço, localização;
              </li>
              <li>
                <strong>Colhedores:</strong> nome completo, foto;
              </li>
              <li>
                <strong>Médicos:</strong> nome completo, CRM.
              </li>
            </ol>
          </div>

          <h3 className="title">Do armazenamento dos dados pessoais</h3>

          <div className="description">
            <p>
              Os dados pessoais tratados pelo Instituto Hermes Pardini S/A em consequência do uso do Saúde Mob serão armazenados em servidores próprios do Instituto Hermes Pardini S/A.
            </p>
            <p>
              Fica eleito o Foro da cidade de Belo Horizonte, Estado de Minas Gerais, para dirimir quaisquer questões decorrentes destes Termos de Uso, que será regido pelas leis brasileiras.
            </p>
            <p>
              Data da última revisão: 20 de abril de 2022.
            </p>
          </div>

        </div>
      </section>
    </>
  )
}